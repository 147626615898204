import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationManager() {
  const { t } = useTranslation();
  
  return (

        <Navbar className="bg-light border my-1">
          <Nav fill variant="pills">
              <LinkContainer to="/settings" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('SETTINGS')}</strong>
              </LinkContainer>
              <LinkContainer to="/routeline" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('ROUTELINEMGT')}</strong>
              </LinkContainer>
              <LinkContainer to="/routesection" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('LINEMGT')}</strong>
              </LinkContainer>
              <LinkContainer to="/station" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('STATIONMGT')}</strong>
              </LinkContainer>
              <LinkContainer to="/routemap" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('ROUTEMAPMGT')}</strong>
              </LinkContainer>
              <LinkContainer to="/robocar" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('ROBOCARMGT')}</strong>
              </LinkContainer>
              <LinkContainer to="/qrscan" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('QRSCAN')}</strong>
              </LinkContainer>
              <LinkContainer to="/carsequence" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('CARSEQUENCE')}</strong>
              </LinkContainer>
              <LinkContainer to="/robotimetable" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>노선시간표</strong>
              </LinkContainer>
              <LinkContainer to="/robocalendar" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>{t('ROBOCALENDAR')}</strong>
              </LinkContainer>
              <LinkContainer to="/drivingrecord" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>운행기록조회</strong>
              </LinkContainer>
              <LinkContainer to="/notes" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>공지사항관리</strong>
              </LinkContainer>
              <LinkContainer to="/downloadcsv" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>히스토리조회</strong>
              </LinkContainer>
              <LinkContainer to="/messagestore" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>메시지조회</strong>
              </LinkContainer>
              <LinkContainer to="/offdestination" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>현장예약</strong>
              </LinkContainer>
              <LinkContainer to="/testsystem" className="nav-link" style={{ cursor: 'pointer' }}>
                  <strong>시스템테스트화면</strong>
              </LinkContainer>
          </Nav>
        </Navbar>

  );
}

export default SubNavigationManager;