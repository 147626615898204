import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import TableDatePicker from "./TableDatePicker";
import * as dateFns from "date-fns";
import { useAppContext } from "../libs/contextLib";

function RoboCalendarNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [applydate, setApplydate] = useState(null);
  const [description, setDescription] = useState("");
  const { routeAppContext } = useAppContext();
  
  function validateForm() {
    return (
      applydate !== null &&
      description.length > 0
    );
  }
  
  function createCalendar(calendar) {
    axiosInstance.post(`/robotaxi/robocalendar/`, calendar).then(function(response) {
      return;
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createCalendar([{
        refyear: dateFns.getYear(applydate),
        applydate: dateFns.format(applydate,"yyyy-MM-dd'T'00:00:00"),
        isholiday: 'Y',
        apigubun: 'Y',
        description: description,
        route: routeAppContext,
      }]);
    
      history.push("/robocalendar");
    } catch (e) {
      onError(t('PROCESS_FAIL'));
      setIsLoading(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="applydate">
                <Form.Label>휴무일</Form.Label>
                <TableDatePicker
                        wrapperClassName="datepicker"
                        selected={applydate}
                        onChange={setApplydate} 
                    />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>내용</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboCalendarNew;