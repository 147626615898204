import React, { useState } from "react";

const html = `
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>자동결제</span></b><b><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span></b><b><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용약관</span></b></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>&#8226; 금융거래정보의 제공 동의<span lang=EN-US><br>
<br>
1. </span>“금융실명거래 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>및 비밀보장에 관한 법률”의 규정에 따라<span
lang=EN-US>,&nbsp;</span>이용자는 본 신청과 관련하여 다음 금융거래정보를 상기 청구기관<span lang=EN-US>(</span>청구기관의<span
lang=EN-US><br>
</span>결제 대행업체를 포함함<span lang=EN-US>)</span>에 제공하는 것에 동의합니다<span lang=EN-US>.<br>
&nbsp;<br>
-&nbsp;&nbsp;&nbsp;</span>거래 카드사<span lang=EN-US>(</span>은행<span lang=EN-US>)</span>명<span
lang=EN-US><br>
-&nbsp;&nbsp;&nbsp;</span>카드번호<span lang=EN-US><br>
<br>
2.&nbsp;</span>상기<span lang=EN-US>&nbsp;1</span>의 이용자<span lang=EN-US><br>
</span>동의는 이용자가 신용카드 등 자동결제를 신규 신청하는 때로부터 해지 신청할 때까지 유효합니다<span lang=EN-US>.<br>
<br>
</span>&#8226; 신용카드 등 자동결제약관<span lang=EN-US><br>
&nbsp;<br>
1.&nbsp;</span>이용자가 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>신청하신 신용카드 등 자동결제는 나이스페이먼츠 주식회사<span
lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;</span>“회사”라 합니다<span lang=EN-US>)</span>에서
대행합니다<span lang=EN-US>.&nbsp;</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>이에 따라 이용자의 신용카드 결제 내역에는 자동결제와 관련한
이용가맹점이 나이스페이먼츠로 표기됩니다<span lang=EN-US>.&nbsp;</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>단<span lang=EN-US>,&nbsp;</span>이용가맹점에서
대행가맹점번호가 아닌 이용가맹점의 가맹점번호를 사용하는 경우 이용가맹점으로 표기될 수 있습니다<span lang=EN-US>.<br>
&nbsp;<br>
2.&nbsp;</span>이용자는 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>본인<span lang=EN-US>(</span>카드소유주<span
lang=EN-US>)</span>이 납부하여야 할 요금에 대하여 별도의 통지 없이 본인의 카드정보를 사용하여 청구기관이 정한 지정 승인일<span
lang=EN-US>(</span>휴일인 경우 익영업일<span lang=EN-US>)</span>에 승인 납부하는데 이의가 없습니다<span
lang=EN-US>.<br>
&nbsp;<br>
3.&nbsp;</span>신용카드 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>등 자동결제를 위하여 지정카드번호로 승인하는 경우에는 회사가
정한 지정카드의 유효성 인증 절차후 신용카드이용약관이나 약정서에 규정된 안심클릭 혹은<span lang=EN-US>&nbsp;ISP</span>인증
등의 절차없이 결제가 진행됩니다<span lang=EN-US>.<br>
&nbsp;<br>
4.&nbsp;</span>신용카드 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>등 자동결제 승인한도가 지정 출금일 현재 청구기관의 청구금액보다
부족하거나<span lang=EN-US>,&nbsp;</span>신용카드의 사용정지 또는 신용카드 대금의 연체 등으로 대체 청구가 불가능한 경우의
손해는 이용자가 부담합니다<span lang=EN-US>.<br>
&nbsp;<br>
5.&nbsp;</span>신용카드 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>등 자동결제 신규신청에 의한 승인개시일은 회사가 청구기관으로부터
사전 통지 받은 승인일입니다<span lang=EN-US>.<br>
&nbsp;<br>
6.&nbsp;</span>신용카드 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>등 자동결제 신청에 의한 지정카드 번호에서의 승인은 청구기관의
청구대로 승인키로 하며 승인금액에 이의가 있는 경우에는 본인과 청구기관이 협의하여<span lang=EN-US><br>
</span>조정합니다<span lang=EN-US>.<br>
&nbsp;<br>
7.&nbsp;</span>회사는 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>이용자의 본 신청과 관련한 거래내역에 대한 별도 조회<span
lang=EN-US>(URL</span>등 이용방법<span lang=EN-US>)&nbsp;</span>수단을 제공합니다<span
lang=EN-US>.<br>
&nbsp;<br>
8.&nbsp;</span>이 약관은 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>신청서를 청구기관에 직접 제출하여 신용카드 등 자동결제를 신청한
경우에도 적용합니다<span lang=EN-US>.<br>
&nbsp;<br>
9.&nbsp;</span>기타 </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='color:black'>본 약관에서 규정하지 않은 사항은 여신전문금융업법 등 관련법령<span
lang=EN-US>, </span>신용카드이용약관 및 회사의 일반약관을 따릅니다<span lang=EN-US>.</span></span></p>
`;

function TermsOfAutoBilling() {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default TermsOfAutoBilling;