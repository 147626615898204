import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RoboTimeTableNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    timehour: "",
    timeminute: "",
    route: "",
    station: "",
    routesection: ""
  });
  
  const [routeOptions, setRouteOptions] = useState([]);
  const [routesectionOptions, setRoutesectionOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  
  useEffect(() => {
    async function onLoad() {
      await axiosInstance.get('/robotaxi/route/',{
        params: {id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const routes = response.data;
        setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
      }).catch(function (error) {
        console.log(error);
      });

      await axiosInstance.get('/robotaxi/routesection/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const sections = response.data;
        setRoutesectionOptions(sections.map( data => ({ name: data.sectionname, id: data.id, routesection:data} ) ));
      }).catch(function (error) {
        console.log(error);
      });
      
      await axiosInstance.get('/robotaxi/station/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const stations = response.data;
        setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
      }).catch(function (error) {
        console.log(error);
      });
    }
    
    onLoad();
  }, []);
  
  function validateForm() {
    return (
      true
      //fields.carname.length > 0
    );
  }
  
  function createRobotimetable(robotimetable) {
    //console.log(robotimetable);
    axiosInstance.post(`/robotaxi/robotimetable/`, robotimetable).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      console.log(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createRobotimetable({
        time_hour: fields.timehour,
        time_minute: fields.timeminute,
        route: fields.route.id,
        station: fields.station.id,
        routesection: fields.routesection.id
      });
    
      history.push("/robotimetable");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.route = result.route;
    //this.setState({ selectedValue: event.target.value });
  };

  function onChangeRoutesection(event) {
    const result = routesectionOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.routesection = result.routesection;
    //this.setState({ selectedValue: event.target.value });
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.station = result.station;
    //this.setState({ selectedValue: event.target.value });
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="구간"
                        options={routeOptions}
                        defaultValue={fields.route.id}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="routesection">
                <DropdownSelector
                        labelName="노선"
                        options={routesectionOptions}
                        defaultValue={fields.routesection.id}
                        onChange={onChangeRoutesection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={fields.station.id}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="timehour">
                <Form.Label>시(hour)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.timehour}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="timeminute">
                <Form.Label>분(minute)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.timeminute}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboTimeTableNew;