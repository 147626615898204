import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import * as dateFns from "date-fns";
import LoadingSpinner from "../components/LoadingSpinner";

function RoboCalendar() {
  const { t } = useTranslation();
  const [calendars, setCalendars] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  
  const columns = [
    {
        dataField: "applydate",
        text: "날짜"
    },
    {
        dataField: "description",
        text: "내용"
    },
    {
        dataField: "crt_date",
        text: "등록일"
    }
  ];
  
  useEffect(() => {
    async function onLoad() {
      try {
        setIsLoading(true);
        axiosInstance.get('/robotaxi/robocalendar/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
            response.data.map( (data_row) => {
                let calendar = {};
                
                calendar['id'] = data_row.id;
                calendar['applydate'] = dateFns.format(new Date(data_row.applydate),'yyyy.MM.dd');
                calendar['description'] = data_row.description;
                calendar['crt_date'] = dateFns.format(new Date(data_row.crt_date),'yyyy.MM.dd');
                setCalendars(calendars => [...calendars, calendar]);
                //setBookingCar(bookingCar.concat(book))
            });
            setIsLoading(false);
        }).catch(function (error) {
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
          setIsLoading(false);
        }); 
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);
  
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
        history.push(`/robocalendar/${row['id']}`);
    }
  };
  
  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
     bgColor: 'skyblue'
  };
  
  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
  <Stack gap={3}>
      <div className="bg-light border">
          <LinkContainer to="/robocalendar/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New RoboCalendar</span>
          </ListGroup.Item>
        </LinkContainer>
        {isLoading ? <LoadingSpinner /> : ""}
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={calendars}
            selectRow={selectRowProp}
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            rowEvents={ rowEvents }
            pagination={paginationFactory(options)}
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboCalendar;