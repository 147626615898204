import React from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";
import SubNavigationManager from "./SubNavigationManager";
import SubNavigationUser from "./SubNavigationUser";
import { useHistory } from "react-router-dom";

function SubNavigation(props) {
  const { t } = useTranslation();
  const history = useHistory();
  
  return (
    <>
      { (props.currentLocation === '/settings') ? <SubNavigationManager /> : <SubNavigationUser /> }
    </>
  );
}

export default SubNavigation;