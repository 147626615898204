
import React from "react";
import "./ToggleSwitch_sm.css";
  
const ToggleSwitch = ({ label, isChecked, onChange }) => {
  return (
    <div className="container">
      <div className="labelTitle">
        {label}{"  "}
      </div>
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={isChecked} onChange={onChange}/>
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;