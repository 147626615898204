import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';

function RouteMap() {
  const { t } = useTranslation();
  const [routemaps, setRoutemaps] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  
  const columns = [
    {
        dataField: "sequence",
        text: "순서"
    },
    {
        dataField: "routesection",
        text: "노선"
    },
    {
        dataField: "station",
        text: "정류장"
    },
    {
        dataField: "leadtime",
        text: "이동시간(분)"
    },
    {
        dataField: "charge",
        text: "운임(원)"
    },
    {
        dataField: "rundistance",
        text: "이동거리(km)"
    },
    {
      dataField: "drtid",
      text: "drtid"
  }
  ];
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get('/robotaxi/routemap/',{
          params: {id: routeAppContext.id}
        }).then(function(response) {
            
            response.data.map( (data_row) => {
                let routemap = {};
                
                routemap['id'] = data_row.id
                routemap['sequence'] = data_row.sequence
                routemap['routesection'] = data_row.routesection.sectionname
                routemap['station'] = data_row.station.stationname
                routemap['leadtime'] = data_row.leadtime
                routemap['charge'] = data_row.charge
                routemap['rundistance'] = data_row.rundistance
                routemap['drtid'] = data_row.drtid
                setRoutemaps(routemaps => [...routemaps, routemap])
                //setBookingCar(bookingCar.concat(book))
            });
        }).catch(function (error) {
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
        }); 
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);
  
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
        history.push(`/routemap/${row['id']}`);
    }
  };
  
  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
     bgColor: 'skyblue'
  };
  
  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        {t('ROUTEMAPMGT')}
      </div>
      <div className="bg-light border">
          <LinkContainer to="/routemap/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New RouteMap</span>
          </ListGroup.Item>
        </LinkContainer>
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={routemaps}
            selectRow={selectRowProp}
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            rowEvents={ rowEvents }
            pagination={paginationFactory(options)}
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteMap;