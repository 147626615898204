import React, { useState } from "react";

const html = `
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span></b><b><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span></b><b><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리방침</span></b></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#585852'>㈜소네트</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>이하</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;&quot;</span><span style='font-size:9.0pt;font-family:
굴림;color:#585852'>회사</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#585852'>&quot;</span><span style='font-size:
9.0pt;font-family:굴림;color:#585852'>라</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>한다</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>고객의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>중요시하며</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>, &quot;</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>정보통신망</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>이용촉진</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보호</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>법률을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>준수하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#585852'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>개인정보취급방침을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>통하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>고객께서</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>제공하시는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>개인정보가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>어떠한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>용도와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>방식으로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>이용되고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>있으며</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>개인정보보호를</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>위해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>어떠한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>조치가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>취해지고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>있는지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>알려드립니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>회사는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>개인정보취급방침을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>개정하는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>경우</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>웹사이트</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>/</span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>앱의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>공지사항</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>또는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>개별공지</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>)</span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>통하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>공지할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>것입니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>1 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>개인정보의</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용목적</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>다음의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>목적을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>활용합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&nbsp;</span><span style='font-size:9.0pt;font-family:
굴림;color:#666666'>서비스</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제공에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>계약</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이행</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제공에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따른</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요금정산</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp; </span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>콘텐츠</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제공</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>구매</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요금</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>결제</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>물품배송</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>청구지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>발송</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>인증</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>구매</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요금추심</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관리</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp; </span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회원제</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따른</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본인확인</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>식별</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>불량회원의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>부정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방지와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>비인가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방지</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가입</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의사</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>확인</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가입</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가입횟수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제한</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,
</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>추후</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대리인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본인확인</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>, </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>분쟁</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>조정을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록보존</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>, </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>불만처리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>민원처리</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>, </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>고지사항</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전달</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>&nbsp;</span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>마케팅</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>광고에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>활용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>신규</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제품</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개발</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>특화</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이벤트</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>광고성</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전달</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>인구통계학적</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>특성에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따른</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제공</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>광고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>게재</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>접속</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>빈도</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파악</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>통계</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>2 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>수집하는</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>항목</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집방법</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>㈜소네트</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이하</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&quot;</span><span style='font-size:9.0pt;font-family:
굴림;color:#666666'>회사</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&quot;</span><span style='font-size:
9.0pt;font-family:굴림;color:#666666'>라</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>한다</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원가입</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>상담</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신청</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아래와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>같은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①<span
lang=EN-US>&nbsp;&nbsp;</span>수집항목<span lang=EN-US>:&nbsp; </span>이름<span
lang=EN-US>,&nbsp;</span>생년월일<span lang=EN-US>,&nbsp;</span>성별<span lang=EN-US>,&nbsp;</span>로그인<span
lang=EN-US>ID,&nbsp;</span>비밀번호<span lang=EN-US>,&nbsp;</span>주소<span
lang=EN-US>,&nbsp;</span>휴대 전화번호<span lang=EN-US>,&nbsp;</span>이메일<span
lang=EN-US>,&nbsp;</span>신용 카드정보<span lang=EN-US>,&nbsp;</span>서비스 이용기록<span
lang=EN-US>,&nbsp;</span>접속 로그<span lang=EN-US>,&nbsp;</span>결제 기록</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②<span
lang=EN-US>&nbsp;&nbsp;&nbsp;</span>개인정보 수집방법<span lang=EN-US>:&nbsp;</span>웹사이트
및 모바일 어플리케이션<span lang=EN-US>&nbsp;(</span>회원가입<span lang=EN-US>,&nbsp;</span>게시판<span
lang=EN-US>,&nbsp;</span>경품응모<span lang=EN-US>,&nbsp;</span>제휴서비스 가입<span
lang=EN-US>),&nbsp;</span>서면양식<span lang=EN-US>,&nbsp;</span>전화<span
lang=EN-US>/</span>팩스를 통한 회원가입<span lang=EN-US>,&nbsp;</span>경품 행사 응모<span
lang=EN-US>,&nbsp;</span>배송 요청<span lang=EN-US>,&nbsp;</span>제휴사로부터의 제공<span
lang=EN-US>,&nbsp;</span>생성정보 수집 툴을 통한 수집</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>3 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'> (</span><span style='font-size:
9.0pt;font-family:굴림;color:#666666'>개인정보의</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보유</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용기간</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>원칙적으로</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인정보</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>수집</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용목적이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>달성된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>해당</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>지체</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>없이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>단</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관계법령의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>규정에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보존할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>필요가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아래와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>같이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관계법령에서</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>일정한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기간</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>동안</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보관합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보존항목</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>:&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>이름</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이메일</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>휴대전화번호</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>카드명</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>카드번호</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②결제기록</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보존</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>근거</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전자상거래등에서의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>소비자보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보존</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기간</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>&nbsp;:
5</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>년</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③표시</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>/</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>광고에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:
6</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개월</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>전자상거래등에서의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>소비자보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④계약</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>청약철회</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:
5</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>년</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>전자상거래등에서의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>소비자보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑤대금결제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>재화</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>공급에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:
5</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>년</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>전자상거래등에서의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>소비자보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑥소비자의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>불만</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>분쟁처리에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:
3</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>년</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>전자상거래등에서의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>소비자보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑦신용정보의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>/</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:
3</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>년</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>신용정보의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보호에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>4 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>개인정보의</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>절차</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방법</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>㈜소네트</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이하</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&quot;</span><span style='font-size:9.0pt;font-family:
굴림;color:#666666'>회사</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&quot;</span><span style='font-size:
9.0pt;font-family:굴림;color:#666666'>라</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>한다</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>원칙적으로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용목적이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>달성된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>해당</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>지체없이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>파기절차</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방법은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>다음과</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>같습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>파기절차</span><span lang=EN-US style='font-size:
9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>고객이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원가입</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>입력하신</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>목적이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>달성된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>별도의</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;DB</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>로</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>옮겨져</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>종이의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>별도의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서류함</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>내부</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>방침</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기타</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관련</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법령에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보보호</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사유에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따라</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>(</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보유</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용기간</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>참조</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>일정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기간</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>저장된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기되어</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>집니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>별도</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;DB</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>옮겨진</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법률에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아니고서는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보유되어</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>지는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이외의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>다른</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>목적으로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용되지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>않습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기방법</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>전자적</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파일형태로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>저장된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기록을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>재생할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>없는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기술적</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방법을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사용하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>삭제합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>종이에</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>출력된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>분쇄기로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>분쇄하거나</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>소각을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>통하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파기합니다</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>5 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>개인정보의</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위탁</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>㈜소네트</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>(</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>이하</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'>&nbsp;&quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>&quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>라</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>한다</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>원칙적으로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>외부에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제공하지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>않습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>다만</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아래의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>예외로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>이용자들이</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사전에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>동의한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>법령의</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>규정에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의거하거나</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>수사</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>목적으로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법령에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정해진</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>절차와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>방법에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따라</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수사기관의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요구가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수집한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위탁</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이행을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아래와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>같이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>외부</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전문업체에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위탁</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제휴를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>통하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>운영하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>ο&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>부록</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'>&nbsp;1.&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위탁</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>현황</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&nbsp; </span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>6 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>이용자</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법정대리인의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>권리와</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>그</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>행사방법</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>언제든지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등록되어</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>자신의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>조회하거나</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수정할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있으며</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가입해지를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요청할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수도</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>조회</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수정을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해서는</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;'</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인정보변경</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>' (</span><span style='font-size:
9.0pt;font-family:굴림;color:#666666'>또는</span><span lang=EN-US style='font-size:
9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>&nbsp;'</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원정보수정</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>'&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>등</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>)</span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>을</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가입해지</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>동의철회</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>위해서는</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&quot;</span><span style='font-size:9.0pt;font-family:
굴림;color:#666666'>회원탈퇴</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'>&quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>클릭하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>확인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>절차를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거치신</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>직접</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>열람</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>탈퇴가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>가능합니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>혹은</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보관리책임자에게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서면</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전화</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이메일로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>연락하시면</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>지체</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>없이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>조치하겠습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>귀하가</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>오류에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정정을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요청하신</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정정을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>완료하기</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>전까지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>당해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제공하지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>않습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>또한</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>잘못된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>3</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>자에게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이미</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제공한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리결과를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>3</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>자에게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>지체없이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>통지하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정정이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이루어지도록</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>하겠습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>혹은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법정</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대리인의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요청에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>해지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>삭제된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보는</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;&quot;</span><span style='font-size:9.0pt;font-family:
굴림;color:#666666'>㈜소네트가</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>수집하는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보유</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용기간</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&quot;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>에</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>명시된</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>바에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>따라</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>그</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>외의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>용도로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>열람</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>또는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>없도록</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>미성년자</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거래시</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>철회</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>미성년자와의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거래시</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사전에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법정대리인</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>부모</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>동의를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>구할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>의무가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있으며</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>, </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>법정대리인</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>부모</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>동의를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>얻지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>못한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거래의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,
</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>거래를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>취소할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>. </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>또한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거래</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사자인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>미성년자의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>법정대리인</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>부모</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거래</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>성립</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>후</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>
7</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>일</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이내에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>철회를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>요청할</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,
</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>거래를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>철회</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>(</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>환불</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>하겠습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>7 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>개인정보</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>자동수집</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>장치의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>설치</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>운영</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>그</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>거부에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사항</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>귀하의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수시로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>저장하고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>찾아내는</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;'</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>쿠키</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>(cookie)'&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>등을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>운용하지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>않습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>쿠키란</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>회사의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>웹사이트를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>운영하는데</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용되는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서버가</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>귀하의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>브라우저에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>보내는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아주</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>작은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>텍스트</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>파일로서</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>귀하의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>컴퓨터</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>하드디스크에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>저장됩니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>정보보안의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이유로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>쿠키를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>운용하지</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>않습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> <span
lang=EN-US>8 </span></span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>조</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> <span lang=EN-US>(</span></span><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>개인정보에</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>민원서비스</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>고객은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용하며</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>발생하는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>모든</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보보호</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관련</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>민원을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보관리책임자에게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신고하실</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자들의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신고사항에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신속하게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>충분한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>답변을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>드릴</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>것입니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp; </span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인정보</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>관리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>책임자</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>부서명</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사업개발팀</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>성명</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;: </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>권기만</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>직위</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;: </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>팀장</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>- E-Mail : kiman.kwon@sonnet.ai</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp; </span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인정보</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>관리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>담당자</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>부서명</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;:&nbsp;##</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>성명</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>구본웅</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>직위</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>팀원</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>-&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>전화</span><span lang=EN-US style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#666666'>&nbsp;: 053-785-4760</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>- E-Mail : bonung.gu@sonnet.ai</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>귀하께서는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>서비스를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>중에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>발생하는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>모든</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보보호</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>관련</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>민원을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보관리책임자</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>혹은</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>담당부서로</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신고하실</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>있습니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사는</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용자들의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신고사항에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대해</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신속하게</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>충분한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>답변을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>드릴</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>것입니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>기타</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보침해에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>대한</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>신고나</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>상담이</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>필요하신</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>경우에는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>아래</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기관에</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>문의하시기</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>바랍니다</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>.</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>1.</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보침해신고센터</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span lang=EN-US style='color:black'><a
href="http://www.1336.or.kr/%EA%B5%AD%EB%B2%88%EC%97%86%EC%9D%B4"
target="_blank"><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666;text-decoration:none'>www.1336.or.kr/</span><span lang=EN-US
style='font-size:9.0pt;font-family:굴림;color:#666666;text-decoration:none'><span
lang=EN-US>국번없이</span></span></a></span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#666666'>&nbsp;118)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>2.</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>정보보호마크인증위원회</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span lang=EN-US style='color:black'><a
href="http://www.eprivacy.or.kr/02-580-0533~4" target="_blank"><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666;
text-decoration:none'>www.eprivacy.or.kr/02-580-0533~4</span></a></span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>3.</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>대검찰청</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>인터넷범죄수사센터</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span lang=EN-US style='color:black'><a
href="http://icic.sppo.go.kr/02-3480-3600" target="_blank"><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666;
text-decoration:none'>http://icic.sppo.go.kr/02-3480-3600</span></a></span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>)</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>4.</span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>경찰청</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>사이버테러대응센터</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>(</span><span lang=EN-US style='color:black'><a
href="http://www.ctrc.go.kr/02-392-0330" target="_blank"><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666;
text-decoration:none'>www.ctrc.go.kr/02-392-0330</span></a></span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>■&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>개인정보처리방침의</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'> </span><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개정</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>ο&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>시행일자</span><span lang=EN-US style='font-size:
9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;: 2022</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>년</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;1</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>월</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;5</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>일</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'><br clear=all>
</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:13.5pt;text-align:left;text-indent:-13.5pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#585852'>부록</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;1.&nbsp;</span><span style='font-size:9.0pt;font-family:
굴림;color:#585852'>개인정보</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>위탁</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>처리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>현황</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;&lt;2022</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>년</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;01</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>월</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&nbsp;05</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>일</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>기준</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>&gt;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:13.5pt;text-align:left;text-indent:-13.5pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>ο&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>위탁</span><span style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#585852'> </span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>대상자</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#585852'>:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>현대해상화재보험㈜본인</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>이용차량의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험을</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>담당하는</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험회사</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;(</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>보험회사가</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>추가</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>변경될</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>수</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>있음</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>)</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>-&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>위탁업무</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>내용</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>:&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:10.0pt;text-align:left;line-height:11.25pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#585852'>차량</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>사고시</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>처리</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>및</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보상</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>비용</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>정산</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>(</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>청구</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>),&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험금</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>지급</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>/</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>심사</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>,&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험사고</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>조사</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>(</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>보험사기</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>조사</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>포함</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>위하여</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>다음의</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>개인</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>(</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>신용</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>)</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>정보를</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>제공</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>ο&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>위탁</span><span style='font-size:9.0pt;font-family:
"Nanum_Barun_regular",serif;color:#585852'> </span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>대상자</span><span lang=EN-US style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#585852'>:&nbsp;ex)</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>나이스페이먼츠㈜</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>,&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>데이터얼라이언스</span><span style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'> </span><span style='font-size:9.0pt;font-family:굴림;color:#585852'>주식회사</span><span
lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>_PG</span><span style='font-size:9.0pt;font-family:굴림;
color:#585852'>사</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>-&nbsp;&nbsp;&nbsp;</span><span style='font-size:9.0pt;
font-family:굴림;color:#585852'>위탁업무</span><span style='font-size:9.0pt;
font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>내용</span><span lang=EN-US
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'>:&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>전자</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>결제</span><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#585852'> </span><span
style='font-size:9.0pt;font-family:굴림;color:#585852'>서비스</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:12.8pt;text-align:left;text-indent:-12.8pt;
line-height:11.25pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;
color:#585852'>&nbsp;</span></p>
`;

function TermsOfPrivacy() {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default TermsOfPrivacy;