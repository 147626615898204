import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import LoginBasic from "./containers/LoginBasic";
import Signup from "./containers/Signup";
import SignupBasic from "./containers/SignupBasic";
import SignupPhone from "./containers/SignupPhone";
import BookingStation from "./containers/BookingStation";
import BookingStationStart from "./containers/BookingStationStart";
import BookingStationEnd from "./containers/BookingStationEnd";
import BillRoute from "./containers/BillRoute";
import BookingCar from "./containers/BookingCar";
import Settings from "./containers/Settings";
import RouteLIne from "./containers/RouteLIne";
import RouteLineNew from "./containers/RouteLineNew";
import RouteLineInfo from "./containers/RouteLineInfo";
import RouteSection from "./containers/RouteSection";
import RouteSectionInfo from "./containers/RouteSectionInfo";
import RouteSectionNew from "./containers/RouteSectionNew";
import Station from "./containers/Station";
import StationNew from "./containers/StationNew";
import StationInfo from "./containers/StationInfo";
import RoboCar from "./containers/RoboCar";
import RoboCarNew from "./containers/RoboCarNew";
import RoboCarInfo from "./containers/RoboCarInfo";
import RouteMap from "./containers/RouteMap";
import RouteMapNew from "./containers/RouteMapNew";
import RouteMapInfo from "./containers/RouteMapInfo";
import RoboCalendar from "./containers/RoboCalendar";
import RoboCalendarNew from "./containers/RoboCalendarNew";
import RoboCalendarInfo from "./containers/RoboCalendarInfo";
import QrScan from "./containers/QrScan";
import BoardingTicket from "./containers/BoardingTicket";
import BoardingQrScan from "./containers/BoardingQrScan";
import CarSequenceList from "./containers/CarSequenceList";
import UserDetail from "./containers/UserDetail";
import PasswordResetView from "./containers/PasswordResetView";
import SignupConfirm from "./containers/SignupConfirm";
import OAuth2KakaoRedirectHandler from "./components/OAuth2KakaoRedirectHandler";
import OAuth2GoogleRedirectHandler from "./components/OAuth2GoogleRedirectHandler";
import OAuth2KakaoSignupRedirectHandler from "./components/OAuth2KakaoSignupRedirectHandler";
import OAuth2GoogleSignupRedirectHandler from "./components/OAuth2GoogleSignupRedirectHandler";
import Jtnet2RedirectHandler from "./components/Jtnet2RedirectHandler";
import JtnetGeneral2RedirectHandler from "./components/JtnetGeneral2RedirectHandler";
import NotFound from "./containers/NotFound";
import NoteNew from "./containers/NoteNew_ckeditor";
import NoteInfo from "./containers/NoteInfo_ckeditor";
import Notes from "./containers/Notes_ckeditor";
import DrivingRecord from "./containers/DrivingRecord";
import DownloadCsv from "./containers/DownloadCsv";
import MessageStore from "./containers/MessageStore";
import RoboTimeTable from "./containers/RoboTimeTable";
import RoboTimeTableNew from "./containers/RoboTimeTableNew";
import RoboTimeTableInfo from "./containers/RoboTimeTableInfo";
import IntroPage from "./containers/IntroPage";
import BillingForm from "./components/BillingForm";
import TestSystem from "./containers/TestSystem";
import OffDestination from "./containers/OffDestination";

export default function Routes() {
  
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/loginbasic">
        <LoginBasic />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/signupbasic">
        <SignupBasic />
      </Route>
      <Route exact path="/signupphone">
        <SignupPhone />
      </Route>
      <Route exact path="/bookingstation">
        <BookingStation />
      </Route>
      <Route exact path="/bookingstationstart">
        <BookingStationStart />
      </Route>
      <Route exact path="/bookingstationend">
        <BookingStationEnd />
      </Route>
      <Route exact path="/billroute">
        <BillRoute />
      </Route>
      <Route exact path="/bookingcar">
        <BookingCar />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/routeline">
        <RouteLIne />
      </Route>
      <Route exact path="/routeline/new">
        <RouteLineNew />
      </Route>
      <Route exact path="/routeline/:id">
        <RouteLineInfo />
      </Route>
      <Route exact path="/routesection">
        <RouteSection />
      </Route>
      <Route exact path="/routesection/new">
        <RouteSectionNew />
      </Route>
      <Route exact path="/routesection/:id">
        <RouteSectionInfo />
      </Route>
      <Route exact path="/station">
        <Station />
      </Route>
      <Route exact path="/station/new">
        <StationNew />
      </Route>
      <Route exact path="/station/:id">
        <StationInfo />
      </Route>
      <Route exact path="/robocar">
        <RoboCar />
      </Route>
      <Route exact path="/robocar/new">
        <RoboCarNew />
      </Route>
      <Route exact path="/robocar/:id">
        <RoboCarInfo />
      </Route>
      <Route exact path="/routemap">
        <RouteMap />
      </Route>
      <Route exact path="/routemap/new">
        <RouteMapNew />
      </Route>
      <Route exact path="/routemap/:id">
        <RouteMapInfo />
      </Route>
      <Route exact path="/robocalendar">
        <RoboCalendar />
      </Route>
      <Route exact path="/robocalendar/new">
        <RoboCalendarNew />
      </Route>
      <Route exact path="/robocalendar/:id">
        <RoboCalendarInfo />
      </Route>
      <Route exact path="/qrscan">
        <QrScan />
      </Route>
      <Route exact path="/boarding/:id">
        <BoardingTicket />
      </Route>
      <Route exact path="/boardingqrscan">
        <BoardingQrScan />
      </Route>
      <Route exact path="/carsequence">
        <CarSequenceList />
      </Route>
      <Route exact path="/userdetail">
        <UserDetail />
      </Route>
      <Route exact path="/accounts/kakao/callback/react">
        <OAuth2KakaoRedirectHandler />
      </Route>
      <Route exact path="/accounts/google/login/callback">
        <OAuth2GoogleRedirectHandler />
      </Route>
      <Route exact path="/accounts/kakao/callback/react/signup">
        <OAuth2KakaoSignupRedirectHandler />
      </Route>
      <Route exact path="/accounts/google/signup/callback">
        <OAuth2GoogleSignupRedirectHandler />
      </Route>
      <Route exact path="/accounts/jtnet/callback">
        <Jtnet2RedirectHandler />
      </Route>
      <Route exact path="/accounts/jtnet/callback/general">
        <JtnetGeneral2RedirectHandler />
      </Route>
      <Route exact path="/signupconfirm">
        <SignupConfirm />
      </Route>
      <Route exact path="/auth/reset/:uid/:token" component={PasswordResetView}/>
      <Route exact path="/notes/new">
        <NoteNew />
      </Route>
      <Route exact path="/notes/:id">
        <NoteInfo />
      </Route>
      <Route exact path="/notes">
        <Notes />
      </Route>
      <Route exact path="/drivingrecord">
        <DrivingRecord />
      </Route>
      <Route exact path="/downloadcsv">
        <DownloadCsv />
      </Route>
      <Route exact path="/messagestore">
        <MessageStore />
      </Route>
      <Route exact path="/robotimetable">
        <RoboTimeTable />
      </Route>
      <Route exact path="/robotimetable/new">
        <RoboTimeTableNew />
      </Route>
      <Route exact path="/robotimetable/:id">
        <RoboTimeTableInfo />
      </Route>
      <Route exact path="/intropage/:id">
        <IntroPage />
      </Route>
      <Route exact path="/billingform">
        <BillingForm />
      </Route>
      <Route exact path="/testsystem">
        <TestSystem />
      </Route>
      <Route exact path="/offdestination">
        <OffDestination />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}