import React, { useRef, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeConsumer } from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosApi";
import * as dateFns from "date-fns";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import LoadingSpinner from "../components/LoadingSpinner";

registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');

const Wrap = styled.div`
  .saturday { color: rgb(0, 0, 255); } 
  .sunday { color: rgb(255, 0, 0); } 
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    background-color: #E9ECEF;
    border-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
`;

export default function TableDateTimePicker(props) {
 const { t } = useTranslation();
 const [startDate, setStartDate] = useState(new Date());
 const [minTime, setMinTime] = useState(new Date());
 const [maxTime, setMaxTime] = useState(new Date());
 const [injectTimes, setInjectTimes] = useState([]);
 const [excludeTimes, setExcludeTimes] = useState([]);
 const [excludeDates, setExcludeDates] = useState([]);
 const { routeAppContext } = useAppContext();
 const [isLoading, setIsLoading] = useState(false);
 const { strStationAppContext } = useAppContext();
 const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
  
 useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      try {
        //inject times
        //console.log(routeAppContext);
        //console.log(strStationAppContext);
        setInjectTimes([]);
        const response_ttb = await axiosInstance.get('/robotaxi/robotimetable/injecttimes/',{
            params: {
                route_id: routeAppContext.id,
                routesection_id: routeSectionAppContext.id,
                station_id: strStationAppContext.station.id
            }
        });
        if (typeof response_ttb === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        //console.log(response_ttb.data);
        response_ttb.data.map( (data_row) => {
            setInjectTimes(injectTimes => [...injectTimes, dateFns.setHours(dateFns.setMinutes(props.selected ? props.selected : new Date(),data_row.time_minute),data_row.time_hour)]);
        });

        // exclude times
        setExcludeTimes([]);
        //console.log(props.selected);
        if (routeSectionAppContext.routegubun == "B") {
          const response_ext = await axiosInstance.get('/robotaxi/robotimetable/getexcludetimes_applydate/',{
              params: {
                  ing_status: 'F',
                  car_id: props.car.id,
                  route_id: routeAppContext.id,
                  routesection_id: routeSectionAppContext.id,
                  applydate: props.selected ? dateFns.format(props.selected,"yyyy-MM-dd'T'00:00:00") : dateFns.format(new Date(),"yyyy-MM-dd'T'00:00:00"),
                  chk_realtime: props.chk_realtime
              }
          });
          if (typeof response_ext === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response_ext.data);
          response_ext.data.excludetimes.map( (data_row) => {
              if ( dateFns.format(props.selected ? props.selected : new Date(),"yyyy-MM-dd'T'00:00:00") === dateFns.format(new Date(data_row.a_date),"yyyy-MM-dd'T'00:00:00") ) {
                  setExcludeTimes(excludeTimes => [...excludeTimes, new Date(data_row.a_date)])
              }
          });
        } else {
          const response_ext = await axiosInstance.get('/robotaxi/robotimetable/excludetimes_applydate/',{
            params: {
                ing_status: 'F',
                car_id: props.car.id,
                route_id: routeAppContext.id,
                routesection_id: routeSectionAppContext.id,
                applydate: props.selected ? dateFns.format(props.selected,"yyyy-MM-dd'T'00:00:00") : dateFns.format(new Date(),"yyyy-MM-dd'T'00:00:00"),
                chk_realtime: props.chk_realtime
            }
          });
          if (typeof response_ext === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response_ext.data);
          response_ext.data.excludetimes.map( (data_row) => {
              if ( dateFns.format(props.selected ? props.selected : new Date(),"yyyy-MM-dd'T'00:00:00") === dateFns.format(new Date(data_row.a_date),"yyyy-MM-dd'T'00:00:00") ) {
                  setExcludeTimes(excludeTimes => [...excludeTimes, new Date(data_row.a_date)])
              }
          });
        }
        
        //exclude dates
        setExcludeDates([]);
        const response_edt = await axiosInstance.get('/robotaxi/robocalendar/maxdate/',{
          params: {route_id: routeAppContext.id}
        });
        if (typeof response_edt === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        //console.log(response_edt.data);
        response_edt.data.map( (data_row) => {
            setExcludeDates(excludeDates => [...excludeDates, new Date(data_row.applydate)]);
        });
        setIsLoading(false);
        
        //set min, maxtime
        setMinTime(new Date(0, 0, 0, 6, 0)); // 8:00am
        setMaxTime(new Date(0, 0, 0, 23, 30)); // 4:30pm
        
      } catch (e) {
        //onError(e);
      }
    }

    onLoad();
  }, [strStationAppContext]);  

 // 월/일 
 const getFormattedDate = (date) => { 
     const month = date.toLocaleDateString('ko-KR', { 
         month: 'long', 
     }); 
     const day = date.toLocaleDateString('ko-KR', { 
         day: 'numeric', 
     }); 
     return `${month.substr(0, month.length - 1)}/${day.substr(0, day.length - 1)}`; 
  } 
  // 요일 반환 
  const getDayName = (date) => { 
      return date.toLocaleDateString('ko-KR', { 
          weekday: 'long', 
      }).substr(0, 1); 
  } // 날짜 비교시 년 월 일까지만 비교하게끔 
  const createDate = (date) => { 
      return new Date(new Date(date.getFullYear() 
        , date.getMonth() 
        , date.getDate() 
        , 0 
        , 0 
        , 0)); 
  }
  
  const filterPassedDay = (time) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1)
    const selectedDate = new Date(time);

    return currentDate < selectedDate;
  };
  
  const filterPassedTime = (time) => {
    const minDate = new Date();
    const maxDate = new Date();
    const selectedDate = new Date(time);
    maxDate.setHours(17);

    return minDate.getTime() < selectedDate.getTime();
  };
  
  const isLunchTime = (date) => {
    const hour = date.getHours();
    if (routeSectionAppContext.routegubun == "B") {
      return true;
    } else {
      return hour !== 12;
    }
  };
  
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  
  const setExcludeTime = (event) => {
    setExcludeTimes([]);
    let select_date = dateFns.format(event,"yyyy-MM-dd'T'00:00:00");
    setMinTime(new Date(0, 0, 0, 6, 0)); // 6:00am
    setMaxTime(new Date(0, 0, 0, 23, 30)); // 11:30pm
    // exclude times
    if (routeSectionAppContext.routegubun == "B") {
      axiosInstance.get('/robotaxi/robotimetable/getexcludetimes_applydate/',{
        params: {
          ing_status: 'F',
          car_id: props.car.id,
          route_id: routeAppContext.id,
          routesection_id: routeSectionAppContext.id,
          applydate: select_date,
          chk_realtime: props.chk_realtime
        }
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          response.data.excludetimes.map( (data_row) => {
              if ( dateFns.format(new Date(data_row.a_date),"yyyy-MM-dd'T'00:00:00") === select_date ) {
                  setExcludeTimes(excludeTimes => [...excludeTimes, new Date(data_row.a_date)])
              }
          });
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        } else {
          onError(t('PROCESS_FAIL'));
        }
      });
    } else {
      axiosInstance.get('/robotaxi/robotimetable/excludetimes_applydate/',{
        params: {
          ing_status: 'F',
          car_id: props.car.id,
          route_id: routeAppContext.id,
          routesection_id: routeSectionAppContext.id,
          applydate: select_date,
          chk_realtime: props.chk_realtime
        }
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          response.data.excludetimes.map( (data_row) => {
              if ( dateFns.format(new Date(data_row.a_date),"yyyy-MM-dd'T'00:00:00") === select_date ) {
                  setExcludeTimes(excludeTimes => [...excludeTimes, new Date(data_row.a_date)])
              }
          });
      }).catch(function (error) {
        setIsLoading(false);
      });
    }
  }
  
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

 return (
   <Wrap>
    {isLoading ? <LoadingSpinner /> : ""}
    <DatePicker
        placeholderText={t('DATE001')}
        //filterDate={isWeekday}
        filterTime={isLunchTime}
        minDate={new Date()}
        maxDate={dateFns.addMonths(new Date(),1)}
        minTime={minTime} // 6:00am
        maxTime={maxTime} // 23:30pm
        excludeDates={excludeDates}
        excludeTimes={excludeTimes}
        startDate={startDate}
        inputmode="none"
        showTimeSelect
        isClearable
        timeIntervals={1440}
        injectTimes={injectTimes}
        dateFormat="yy.MM.dd(eee) HH:mm"
        locale={ko}
        selected={props.selected}
        onChange={props.onChange}
        onFocus={e => e.target.blur()}
        onChangeRaw={handleDateChangeRaw}
        onSelect={setExcludeTime}
        // 토요일, 일요일 색깔 바꾸기 위함 
        dayClassName={date => 
            getDayName(createDate(date)) === '토' ? "saturday" 
            : 
            getDayName(createDate(date)) === '일' ? "sunday" : undefined 
        }
    />
  </Wrap>
 );
}