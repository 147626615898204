import React, { useState, useEffect, useCallback } from "react";
import { TwitterPicker } from 'react-color';
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import TableTimePicker from "./TableTimePicker";
import ToggleSwitch from "../components/ToggleSwitch";
import * as dateFns from "date-fns";

function RouteSectionInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [section, setSection] = useState(null);
  const [sectionname, setSectionname] = useState("");
  const [mapurl, setMapurl] = useState("");
  const [sectioncolor, setSectioncolor] = useState("");
  const [route, setRoute] = useState(null);
  const [drtid, setDrtid] = useState("");

  const [routegubun, setRoutegubun] = useState("");
  const [charge, setCharge] = useState("");
  const [persons, setPersons] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [content, setContent] = useState("");
  const [is_publish, setIs_publish] = useState(false);
  const [is_pay, setIs_pay] = useState(false);
  const [is_round, setIs_round] = useState(false);
  const [cmsid, setCmsid] = useState("");

  const [is_goworkhome, setIs_goworkhome] = useState(false);
  const [goworkfrom, setGoworkfrom] = useState(new Date());
  const [goworkto, setGoworkto] = useState(new Date());
  const [gohomefrom, setGohomefrom] = useState(new Date());
  const [gohometo, setGohometo] = useState(new Date());
  const [sparetime, setSparetime] = useState("");

  const onSwitchActionPublish = () => {
    setIs_publish(!is_publish);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionPay = () => {
    setIs_pay(!is_pay);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionRound = () => {
    setIs_round(!is_round);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionGoWorkHome = () => {
    setIs_goworkhome(!is_goworkhome);
    //console.log(isSwitchOnPrivate);
  };

  function onChangeGoworkfrom(event) {
    //console.log(dateFns.format(event,"yyyy-MM-dd'T'HH:mm:00"));
    setGoworkfrom(event);
  }

  function onChangeGoworkto(event) {
    setGoworkto(event);
  }

  function onChangeGohomefrom(event) {
    setGohomefrom(event);
  }

  function onChangeGohometo(event) {
    setGohometo(event);
  }

  useEffect(() => {
    async function onLoad() {
      axiosInstance.get(`/robotaxi/routesection/${id}/`).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const rtnData = response.data;
        setSection(rtnData);
        setSectionname(rtnData.sectionname);
        setMapurl(rtnData.mapurl);
        setSectioncolor(rtnData.sectioncolor);
        setRoute(rtnData.route);
        setIs_publish(rtnData.is_publish);
        setIs_pay(rtnData.is_pay);
        setIs_round(rtnData.is_round);
        setCmsid(rtnData.cmsid);
        setRoutegubun(rtnData.routegubun);
        setCharge(rtnData.charge);
        setPersons(rtnData.persons);
        setIs_goworkhome(rtnData.is_goworkhome);
        setGoworkfrom(new Date(rtnData.goworkfrom));
        setGoworkto(new Date(rtnData.goworkto));
        setGohomefrom(new Date(rtnData.gohomefrom));
        setGohometo(new Date(rtnData.gohometo));
        setSparetime(rtnData.sparetime);
        setDrtid(rtnData.drtid);
      }).catch(function (error) {
        console.log(error);
      });
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return (
      sectionname.length > 0
    );
  }

  function saveProc(section) {
    axiosInstance.put(`/robotaxi/routesection/${id}/`, section).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      saveProc({
        sectionname: sectionname,
        sectioncolor: sectioncolor,
        mapurl: mapurl,
        route: route,
        is_publish: is_publish,
        is_pay: is_pay,
        is_round: is_round,
        cmsid: cmsid,
        routegubun: routegubun,
        charge: charge,
        persons: persons,
        is_goworkhome: is_goworkhome,
        goworkfrom: dateFns.format(goworkfrom, "yyyy-MM-dd'T'HH:mm:00"),
        goworkto: dateFns.format(goworkto, "yyyy-MM-dd'T'HH:mm:00"),
        gohomefrom: dateFns.format(gohomefrom, "yyyy-MM-dd'T'HH:mm:00"),
        gohometo: dateFns.format(gohometo, "yyyy-MM-dd'T'HH:mm:00"),
        sparetime: sparetime,
        drtid: drtid
      });

      history.push("/routesection");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteProc() {
    return axiosInstance.delete(`/robotaxi/routesection/${id}/`, section).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this routesection?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteProc();

      history.push("/routesection");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  const handleColorChange = useCallback(
    (color) => {
      setSectioncolor(color.hex);
    }, [sectioncolor])

  return (
    <Stack gap={3}>
      <div className="bg-light border">

        <div className="RouteSection">
          {section && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="sectionname">
                <Form.Label>섹션명</Form.Label>
                <Form.Control
                  type="text"
                  value={sectionname}
                  onChange={(e) => setSectionname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="mapurl">
                <Form.Label>섹션맵 URL</Form.Label>
                <Form.Control
                  type="text"
                  value={mapurl}
                  onChange={(e) => setMapurl(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sectioncolor">
                <Form.Label>색상(HEX)</Form.Label>
                <Form.Control
                  type="text"
                  value={sectioncolor}
                  disabled={true}
                  onChange={(e) => handleColorChange(e.target.value)}
                />
                <TwitterPicker
                  color={sectioncolor}
                  onChangeComplete={handleColorChange}
                />
              </Form.Group>
              <Form.Group controlId="cmsid">
                <Form.Label>CMS Route ID</Form.Label>
                <Form.Control
                  type="text"
                  value={cmsid}
                  onChange={(e) => setCmsid(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="routegubun">
                <Form.Label>노선운영구분(예약호출택시 : A, 고정노선버스 : B )</Form.Label>
                <Form.Control
                  type="text"
                  value={routegubun}
                  onChange={(e) => setRoutegubun(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>운임(운영구분A: 건당, 운영구분B: 인당)</Form.Label>
                <Form.Control
                  type="text"
                  value={charge}
                  onChange={(e) => setCharge(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="persons">
                <Form.Label>우선순위</Form.Label>
                <Form.Control
                  type="text"
                  value={persons}
                  onChange={(e) => setPersons(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sparetime">
                <Form.Label>여유시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={sparetime}
                  onChange={(e) => setSparetime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="goworkhome">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 출퇴근설정</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="출퇴근적용" isChecked={is_goworkhome} onChange={onSwitchActionGoWorkHome} />
                    </Col>
                  </Row>
                  {is_goworkhome &&
                    <Container>
                      <Row>
                        <Form.Label>출근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkfrom}
                            onChange={onChangeGoworkfrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkto}
                            onChange={onChangeGoworkto}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label>퇴근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohomefrom}
                            onChange={onChangeGohomefrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohometo}
                            onChange={onChangeGohometo}
                          />
                        </Col>
                      </Row>
                    </Container>
                  }
                </Container>
              </Form.Group>
              <Form.Group controlId="subject">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="운행" isChecked={is_publish} onChange={onSwitchActionPublish} />
                    </Col>
                    <Col>
                      <ToggleSwitch label="유료" isChecked={is_pay} onChange={onSwitchActionPay} />
                    </Col>
                    <Col>
                      <ToggleSwitch label="예약" isChecked={is_round} onChange={onSwitchActionRound} />
                    </Col>
                  </Row>
                </Container>
              </Form.Group>
              <Form.Group controlId="drtid">
                <Form.Label>drtid</Form.Label>
                <Form.Control
                  type="text"
                  value={drtid}
                  onChange={(e) => setDrtid(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>

      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}

export default RouteSectionInfo;