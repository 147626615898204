// Django Rest Api connetion Utility

import axios from 'axios'
import config from "./config";
import { onError } from "./libs/errorLib";

const baseURL = `${config.DJANGO_URL}`

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? "Bearer " + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

// response interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        
        try {
            const originalRequest = error.config;
            //console.log(error.response);
            if ( error.response?.status === 401 && (!originalRequest?._retry) ) {
                // originalRequest._retry = true;
                // localStorage.clear();
                // window.location.href = "/";
                console.log(originalRequest);
                // const refresh_token = localStorage.getItem('refresh_token');
                // return axiosInstance
                //     .post(`${config.DJANGO_URL}/accounts/token/refresh/`, {refresh: refresh_token})
                //     .then((response) => {

                //         localStorage.setItem('access_token', response.data.access);
                //         localStorage.setItem('refresh_token', response.data.refresh);

                //         axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                //         originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                //         return axiosInstance(originalRequest);
                //     })
                //     .catch(err => {
                //         console.log(err)
                //     });
            } else if ( error.response.status === 412 ) {
                onError("예약에 실패하였습니다. : " + error.response.data);
            } else if ( error.response.status === 444 ) {
                onError(error.response.data.message);
            } else if ( error.response.status === 445 ) {
                onError(error.response.data.message);
            } else if ( error.response.status === 446 ) {
                onError(error.response.data.message);
            } else if ( error.response.status === 447 ) {
                onError(error.response.data.message);
            } else if ( error.response.status === 448 ) {
                onError(error.response.data.message);
            } else {
                onError("문제가 발생했습니다. 관리자(e-mail:raxi@sonnet.ai)에게 문의해 주세요.");
            }
        } catch (err) {
            localStorage.clear();
            window.location.href = "/";
        }     
    }
)

export default axiosInstance