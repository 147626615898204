import React, { useState, useEffect } from "react";
import { Container, Row, Col, Stack, Alert } from 'react-bootstrap';
import axios from 'axios';
import axiosInstance from "../axiosApi";
import config from "../config";
import { useTranslation } from 'react-i18next';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import LoadingSpinner from "../components/LoadingSpinner";
import { onError } from "../libs/errorLib";

function DownloadCsv() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  
  const MyExportData = (props) => {
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ props.onClick } />
            {" "}To {props.type}
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  const exportToCsv = e => {
    e.preventDefault();
    
    axiosInstance.get('/robotaxi/downloadcsv/', {
                params: { csv_type: 'carsequence' }
    }).then(function(response) {
        const blob = new Blob(["\ufeff"+response.data], { type: 'text/csv' + '; charset=utf-8'})

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', '차량시간표.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsLoading(false);
    
    }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if (error && error.response && error.response.status) {
            if (error.response.status === 444) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
          
        } else {
          onError(error);
        }
        setIsLoading(false);
    }); 
  }

  const exportToCsvUser = e => {
    e.preventDefault();
    
    axiosInstance.get('/robotaxi/downloadcsv/', {
                params: { csv_type: 'customuser' }
    }).then(function(response) {
        const blob = new Blob(["\ufeff"+response.data], { type: 'text/csv' + '; charset=utf-8'})

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', '사용자목록.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsLoading(false);
    
    }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if (error && error.response && error.response.status) {
            if (error.response.status === 444) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
          
        } else {
          onError(error);
        }
        setIsLoading(false);
    }); 
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        <div>
        {isLoading ? <LoadingSpinner /> : ""}
        <Container>
            <Row xs="auto" className="align-items-center">
                <Col>
                    <b>차량시간표-전체데이터</b>
                </Col> 
                <Col>
                    <MyExportData onClick={exportToCsv} type='CSV' />
                </Col>
            </Row>
            <Row xs="auto" className="align-items-center">
                <Col>
                    <b>사용자목록-전체데이터</b>
                </Col> 
                <Col>
                    <MyExportData onClick={exportToCsvUser} type='CSV' />
                </Col>
            </Row>
        </Container>
        </div>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default DownloadCsv;