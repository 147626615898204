import React, { useRef, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeConsumer } from "styled-components";
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosApi";
import * as dateFns from "date-fns";

registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');

const Wrap = styled.div`
  .saturday { color: rgb(0, 0, 255); } 
  .sunday { color: rgb(255, 0, 0); } 
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    background-color: #E9ECEF;
    border-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
`;

export default function TableTimePicker(props) {
    const { t } = useTranslation();

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    return (
        <Wrap>
            <DatePicker
                placeholderText={t('DATE001')}
                isClearable
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                locale={ko}
                onFocus={e => e.target.blur()}
                onChangeRaw={handleDateChangeRaw}
                selected={props.selected}
                onChange={props.onChange}
            />
        </Wrap>
    );
}