import React, { useState, useEffect } from "react";
import {Button, Modal} from "react-bootstrap";
import { onError } from "../libs/errorLib";
import { useTranslation } from 'react-i18next';
import config from "../config";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios'
import moment from "moment";
import 'moment/locale/ko';

function DetailModal(props) {
    const { t } = useTranslation();

    const Viewer = ({content}) => (
      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Button variant="outline-success" disabled="true"><i class="fa fa-check"></i> {t('ANNOUNCEMENT')}</Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{props.subject}</h5>
          <p>
            <Viewer content={props.content} />
          </p>
          <span><i class="fas fa-angle-double-right"></i> {t('ATTACHMENTS')}</span>
          {props.filemodels && (
            props.filemodels.map( (data, index) => {
              return ( 
                <>
                  <p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${config.DJANGO_URL}` + data.file_save_name}
                    >
                      {index+1}{"   "}{data.id}{data.file_origin_name}
                    </a>
                  </p>
                </>);
            })
          )}
        </Modal.Body>
        <Modal.Footer>
          {t('DATE_ISSUE')} : {props.crt_date}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function Announcement() {
    const { t } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [crt_date, setCrt_date] = useState("");
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [filemodels, setFilemodels] = useState([]);

    const gubunFormatter = (cell, row) => {
      if (row.is_top) {
        return (
          <span>
            <strong style={ { color: 'red' } }><i class="fas fa-star"></i></strong>
          </span>
        );
      }
    
      return (
        <span><i class="far fa-star"></i></span>
      );
    }

    const columns = [
      {
        dataField: "is_top",
        text: t('PRIORITY'),
        headerStyle: {
          backgroundColor: '#c8e6c9',
          width: '15%'
        },
        formatter: gubunFormatter
      },
      {
          dataField: "crt_date",
          text: t('DATE_ISSUE'),
          headerStyle: {
            backgroundColor: '#c8e6c9',
            width: '30%'
          }
      },
      {
          dataField: "subject",
          text: t('SUBJECT'),
          headerStyle: {
            backgroundColor: '#c8e6c9'
          }
      }
    ];

    useEffect(() => {
        async function onLoad() {
          setNotes([]);
          try {
            axios.get(`${config.DJANGO_URL}/robotaxi/notes/public/`).then(function(response) {
              response.data.map( (data_row) => {
                let note = {};
                note['id'] = data_row.id;
                note['crt_date'] = moment(new Date(data_row.crt_date)).format("yyyy.MM.DD(ddd) HH:mm");
                note['subject'] = data_row.subject;
                note['content'] = data_row.content;
                note['is_top'] = data_row.is_top;
                note['filemodels'] = data_row.filemodels;
                setNotes(notes => [...notes, note]);
                //setBookingCar(bookingCar.concat(book));
              });

            }).catch(function (error) {
              onError(error);
              setIsLoading(false);
            }); 
          } catch (e) {
            onError(e);
          }
    
          setIsLoading(false);
        }
    
        onLoad();
    }, []);

    function showModal() {
      setModalShow(true);
    }
    
    function hideModal() {
      setModalShow(false);
      window.location.reload(true);
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            //console.log(`clicked on row with index: ${rowIndex}`);
            setCrt_date(row.crt_date);
            setSubject(row.subject);
            setContent(row.content);
            setFilemodels(row.filemodels);
            showModal();
        }
    };

    return (
        <>
            <div>
              <Button variant="outline-success" disabled="true" style={{marginBottom:15}}><b><i class="fa fa-check"></i> {t('ANNOUNCEMENT')}</b></Button>
              <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={notes}
                  columns={columns}
                  bordered={ false }
                  rowEvents={ rowEvents }
                  pagination={ paginationFactory() }
              />
            </div>
            <div>
                <DetailModal
                    show={modalShow}
                    onHide={hideModal}
                    crt_date={crt_date}
                    subject={subject}
                    content={content}
                    filemodels={filemodels}
                />
            </div>
        </>
    );
  }

  export default Announcement;