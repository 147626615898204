import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import QRCode from "qrcode.react";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RoboCarInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [car, setCar] = useState(null);
  const [carname, setCarname] = useState("");
  const [carstatus, setCarstatus] = useState("");
  const [route, setRoute] = useState("");
  const [station, setStation] = useState("");
  const [routesection, setRoutesection] = useState("");
  
  const [routeSel, setRouteSel] = useState("");
  const [stationSel, setStationSel] = useState("");
  const [routesectionSel, setRoutesectionSel] = useState("");

  const [routeOptions, setRouteOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [routesectionOptions, setRoutesectionOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([
    { name: "대기",id: "A"},
    { name: "배차",id: "B"},,
    { name: "수리",id: "C"},
  ]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [vehicle_id, setVehicle_id] = useState(null);
  const [icon_src, setIcon_src] = useState("");
  const [persons, setPersons] = useState("");
  const [car_number, setCar_number] = useState("");
  
  useEffect(() => {
    async function onLoad() {
        await axiosInstance.get(`/robotaxi/robocar/${id}/`).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          const car = response.data;
          setCar(car);
          setCarname(car.carname);
          setCarstatus(car.car_status);
          setRoute(car.route);
          setStation(car.station);
          setRoutesection(car.routesection);
          setVehicle_id(car.vehicle_id);
          setRouteSel(car.route.id);
          setRoutesectionSel(car.routesection.id);
          setStationSel(car.station.id);
          setIcon_src(car.icon_src);
          setPersons(car.persons);
          setCar_number(car.car_number);
        }).catch(function (error) {
          //console.log(error); 
        });
        
        await axiosInstance.get('/robotaxi/route/',{
          params: {id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const routes = response.data;
          setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
        }).catch(function (error) {
          //console.log(error); 
        });
        
        await axiosInstance.get('/robotaxi/station/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const stations = response.data;
          setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
        }).catch(function (error) {
          //console.log(error); 
        });

        await axiosInstance.get('/robotaxi/routesection/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const sections = response.data;
          setRoutesectionOptions(sections.map( data => ({ name: data.sectionname, id: data.id, routesection:data }) ));
        }).catch(function (error) {
          //console.log(error); 
        });
        
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      carname.length > 0
    );
  }
  
  async function saveCar(car) {
    //console.log(car);
    axiosInstance.put(`/robotaxi/robocar/${id}/`, car).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveCar({
        id: car.id,
        carname: carname,
        car_status: carstatus,
        route: route.id,
        station: station.id,
        routesection: routesection.id,
        vehicle_id: vehicle_id,
        icon_src: icon_src,
        persons: persons,
        car_number: car_number
      });
    
      history.push("/robocar");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteCar() {
    return axiosInstance.delete(`/robotaxi/robocar/${id}/`, car).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteCar();
      
      history.push("/robocar");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoute(result.route);;
    //this.setState({ selectedValue: event.target.value });
    setRouteSel(event.target.value);
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    setStation(result.station);
    //this.setState({ selectedValue: event.target.value });
    setStationSel(event.target.value);
  };

  function onChangeRoutesection(event) {
    const result = routesectionOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoutesection(result.routesection);
    //this.setState({ selectedValue: event.target.value });
    setRoutesectionSel(event.target.value);
  };
  
  function onChangeState(event) {
    setCarstatus(event.target.value);
    //this.setState({ selectedValue: event.target.value });
  };
  
  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {car && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="carname">
                <Form.Label>차량명</Form.Label>
                <Form.Control
                  type="text"
                  value={carname}
                  onChange={(e) => setCarname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="car_status">
                <DropdownSelector
                        labelName="상태"
                        options={stateOptions}
                        defaultValue={carstatus}
                        onChange={onChangeState}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="구간"
                        options={routeOptions}
                        defaultValue={routeSel}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="routesection">
                <DropdownSelector
                        labelName="노선"
                        options={routesectionOptions}
                        defaultValue={routesectionSel}
                        onChange={onChangeRoutesection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={stationSel}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="vehicle_id">
                <Form.Label>차량 ID</Form.Label>
                <Form.Control
                  type="text"
                  value={vehicle_id}
                  onChange={(e) => setVehicle_id(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon경로</Form.Label>
                <Form.Control
                  type="text"
                  value={icon_src}
                  onChange={(e) => setIcon_src(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="persons">
                <Form.Label>탑승인원</Form.Label>
                <Form.Control
                  type="text"
                  value={persons}
                  onChange={(e) => setPersons(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="car_number">
                <Form.Label>차량번호</Form.Label>
                <Form.Control
                  type="text"
                  value={car_number}
                  onChange={(e) => setCar_number(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div className="bg-light border">
        <QRCode
            id="qrCodeEl"
            value={`{"id": "${id}", "carname": "${carname}"}`}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={true}
        />
        <br/>
        <input 
          type="button" 
          className="download-btn" 
          value="Download" 
          onClick={downloadQRCode} 
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboCarInfo;