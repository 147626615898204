import React, { useState, useEffect, useCallback } from "react";
import { Stack, Modal, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import RouteMapDisplay from "../components/RouteMapDisplay";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import GoogleMapAPI from "../components/GoogleMapAPI";
import GoogleMapReactAPI from "../components/GoogleMapReactAPI";
import Destination from "./Destination";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import config from "../config";

function NotePopupModal(props) {
  const { t } = useTranslation();

  const Viewer = ({content}) => (
    <div
      className="ck-content"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <Button variant="outline-success" disabled="true"><i class="fa fa-check"></i> {t('ANNOUNCEMENT')}</Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.subject}</h5>
        <p>
          <Viewer content={props.content} />
        </p>
        <span><i class="fas fa-angle-double-right"></i> {t('ATTACHMENTS')}</span>
        {props.filemodels && (
          props.filemodels.map( (data, index) => {
            return ( 
              <>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.DJANGO_URL}` + data.file_save_name}
                  >
                    {index+1}{"   "}{data.id}{data.file_origin_name}
                  </a>
                </p>
              </>);
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        {t('DATE_ISSUE')} : {props.crt_date}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function BookingStationStart() {
  const { routeAppContext } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [crt_date, setCrt_date] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [filemodels, setFilemodels] = useState([]);
  const { strStationAppContext } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!routeAppContext) {
      history.push("/");
      return;
    }

    async function onLoad() {
        axiosInstance.get('/robotaxi/route/').then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //1. check Note Popup info
          axiosInstance.get('/robotaxi/notepopup/').then(function(response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            //console.log(response.data);
            if (response.data.length > 0) {
              setCrt_date(response.data[0].crt_date);
              setSubject(response.data[0].subject);
              setContent(response.data[0].content);
              setFilemodels(response.data[0].filemodels);
              showModal();

              axiosInstance.post('/robotaxi/notepopupcheck/', {
                note_id: response.data[0].id
              }).then(function(response) {
                //console.log(response.data);
              }).catch(function (error) {
                console.log(error);
              }); 
            }
          }).catch(function (error) {
            console.log(error);
          });

        }).catch(function (error) {
          setIsLoading(false);
        }); 
    }
    onLoad();
  }, []);

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
    window.location.reload(true);

    history.push("/bookingstation", {replace: true});
  }

  return (
    <Stack gap={2}>
      <div className="bg-light border">
        { strStationAppContext && <GoogleMapAPI /> }
      </div>
      <div className="bg-light">
        <Destination />
      </div>
      <div>
          <NotePopupModal
              show={modalShow}
              onHide={hideModal}
              crt_date={crt_date}
              subject={subject}
              content={content}
              filemodels={filemodels}
          />
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}