import React, { useState } from "react";
import { Nav, Navbar, Stack, Form, Row, Col, Offcanvas, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import config from "../config";
import { setToken } from "../firebase";
import styled from "styled-components";
import kakaoLogo from "../images/kakao_logo.png";
import appleLogo from "../images/apple_icon.png";
import googleLogo from "../images/g-logo.png";
import { KAKAO_AUTH_URL } from "../components/OAuthKakao";
import { GOOGLE_AUTH_URL } from "../components/OAuthGoogle";
import AppleLogin from 'react-apple-login'

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const NaverShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;
  
const KakaoShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;

const KakaoIcon = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 24px;
  `;

function GetEmail({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    props.onChange(email);
  }

  const handleShow = () => {
    setShow(true);
  }

  function validateForm() {
    return phone.length > 0;
  }

  function handleSubmitFind(event) {
    event.preventDefault();

    axios.get(`${config.DJANGO_URL}/accounts/get_user_by_phone/`, {
      params: {
        phone_number: phone
      }
    }).then(function (response) {
      setEmail(response.data[0].email);
    }).catch(function (error) {
      onError(t('READ_FAIL'));
    });
  }

  return (
    <>
      <LoaderButton block variant="dark" onClick={handleShow} >{name}</LoaderButton>

      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white bg-dark">{t('FINDEMAIL')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className=" border">
            <Form onSubmit={handleSubmitFind}>
              <Form.Group controlId="phone">
                <Form.Label>{t('F_EMAIL_LABEL')}</Form.Label>
                <Form.Control
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={email}
                  onChange={props.onChange}
                />
              </Form.Group>
              <LoaderButton
                block
                variant="dark"
                onClick={handleSubmitFind}
                disabled={!validateForm()}
              >
                Email찾기
              </LoaderButton>
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function Login() {
  const { t } = useTranslation();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { setUser } = useAppContext();
  const { routeAppContext } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const { setRouteSectionAppContext } = useAppContext();
  const { setRouteMap } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setLeadtimeAppContext } = useAppContext();

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`,{
      params: {route_id: route_id, is_publish: '1'}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`,{
          params: {id: route_id, section: section_id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`,{
            params: {route_id: route_id, section_id: section_id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
        }).catch(function (error) {
          console.log(error);
        });

        history.push("/bookingstation");
      }).catch(function (error) {
          console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/',{
      params: {route_id: route_id}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/',{
          params: {id: route_id, section: response.data.routesection.id}
        }).then(function(response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })

          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });               
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    }); 
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    axios.post(`${config.DJANGO_URL}/accounts/checklogin/`, {
      email: email
    }).then(function (response) {
      //console.log(response.data);
      axios.post(`${config.DJANGO_URL}/accounts/login/`, {
        email: email,
        password: password
      }).then(function (response) {
        //console.log(response.data);
        //console.log(`Bearer ${response.data.access}`);
        let user = null;
        var route_id = "";
        axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('access_user', JSON.stringify(response.data.user));
        setUser(response.data.user);
        userHasAuthenticated(true);
        user = response.data.user;
        setToken('robotaxi', response.data.user.email);

        //console.log(routeAppContext);
        axiosInstance.get('/robotaxi/route/').then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          if (routeAppContext) {
            initialPostSetting(routeAppContext.id);
          } else {
            let idx = 0;
            let apply_route = null;
            response.data.map( (data_row) => {
              if (data_row.is_publish && data_row.id == user.route_default) {
                apply_route = data_row;
                setRouteAppContext(data_row);
                idx++;
              }
            });
    
            if ( idx == 0 ) {
              apply_route = response.data[0];
              setRouteAppContext(response.data[0]);
            }

            route_id = apply_route.id;
            initialPostSetting(route_id);
          }
        }).catch(function (error) {
          console.log(error.response.data);
        });

      }).catch(function (error) {
        onError(t('LOGIN_FAIL'));

        userHasAuthenticated(false);
        setIsLoading(false);
      });
    }).catch(function (error) {
      //console.log(error.response);
      onError(error.response.data);
      userHasAuthenticated(false);
      setIsLoading(false);
      history.push("/login");
    });
  }

  async function handlePasswordReset(event) {
    window.open(`${config.DJANGO_URL}/auth/password_reset/`);
  }

  const appleResponse = response => {
    if (!response.error) {
      //console.log(response.authorization.code);
      //console.log(response.authorization.id_token);
      axios.get(`${config.DJANGO_URL}/accounts/apple/callback/react/`,
            {
              params:{
                  code:response.authorization.code,
              },
            }
          ).then((response) => {
            //console.log(response.data);
               
            //get token
            let user = null;
            var route_id = "";
            var section_id = "";
            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            localStorage.setItem('access_user', JSON.stringify(response.data.user));
            setUser(response.data.user);
            userHasAuthenticated(true);
            setToken('robotaxi',response.data.user.email);
            user = response.data.user;
            //console.log(routeAppContext);
            axiosInstance.get('/robotaxi/route/').then(function(response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              if (routeAppContext) {
                initialPostSetting(routeAppContext.id);
              } else {
                let idx = 0;
                let apply_route = null;
                response.data.map( (data_row) => {
                  if (data_row.is_publish && data_row.id == user.route_default) {
                    apply_route = data_row;
                    setRouteAppContext(data_row);
                    idx++;
                  }
                });
        
                if ( idx == 0 ) {
                  apply_route = response.data[0];
                  setRouteAppContext(response.data[0]);
                }

                route_id = apply_route.id;
                initialPostSetting(route_id);
              }
            }).catch(function (error) {
              console.log(error.response.data);
            });
        }).catch((error) => {
            console.log(error);
        });
    }
  };

  return (
    <Stack gap={3}>
      <div className="bg-light border">
        <Nav sticky="top">
          <LinkContainer to="/login">
            <Nav.Link>{t('LOGIN')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/signup">
            <Nav.Link>{t('SIGNUP')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/home">
            <Nav.Link>{t('HOME')}</Nav.Link>
          </LinkContainer>
        </Nav>
      </div>
      <div className="bg-light border">
        <div className="Login">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>{t('EMAIL')}</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>{t('PASSWORD')}</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              variant="dark"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              {t('LOGIN')}
            </LoaderButton>
            <LoaderButton
              block
              variant="dark"
              onClick={handlePasswordReset}
              isLoading={isLoading}
            >
              {t('PASSWORD_RESET')}
            </LoaderButton>
            <GetEmail key={1} placement='bottom'
              name={t('FINDEMAIL')}
              onChange={setEmail}
            />
          </Form>
        </div>
        <hr />
        <div className="SocialLogin">
          <FlexContainer>
            <GridContainer>
              <KakaoShareButton href={KAKAO_AUTH_URL} >
                <KakaoIcon src={kakaoLogo}></KakaoIcon>
              </KakaoShareButton>
              {/*
              <KakaoShareButton href={GOOGLE_AUTH_URL}>
                <KakaoIcon src={googleLogo}></KakaoIcon>
              </KakaoShareButton>
              */}
              <AppleLogin
                clientId="ai.sonnet.raxi.web"
                redirectURI={config.REDIRECT_URI_APPLE}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="form_post"
                render={renderProps => (  //Custom Apple Sign in Button
                <KakaoIcon src={appleLogo} onClick={renderProps.onClick}></KakaoIcon>
                            )}
              />
				    </GridContainer>
				  </FlexContainer>
        </div>
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}