import React, { useState, useEffect, useRef, useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker, useLoadScript, InfoWindow, Data, OverlayView } from '@react-google-maps/api';
import { useAppContext } from "../libs/contextLib";
import config from "../config";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";

function GoogleMapReactAPI(props) {
  const { routeAppContext } = useAppContext();
  const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
  const { routeMap, setRouteMap } = useAppContext();
  const { strStationAppContext } = useAppContext();
  const { endStationAppContext } = useAppContext();
  const [car1Date, setCar1Date] = useState(null);
  const [roboCars, setRoboCars] = useState([]);
  const google = window.google
  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const options = useMemo( () => ({
    disableDefaultUI: true,
    clickableIcons: false
  }), []);
  const center = useMemo(() => ({
    lat: Number(routeAppContext.ref_lat), 
    lng: Number(routeAppContext.ref_lng)
  }));
  const [markerPosition, setMarkerPosition] = useState({
    lat: Number(routeAppContext.ref_lat), 
    lng: Number(routeAppContext.ref_lng)
  });

  //const { isLoaded } = useJsApiLoader({
  //  id: 'google-map-script',
  //  googleMapsApiKey: "AIzaSyCjJQ1UO0BgWFnOgtvTinQ4PHzu8FoSVO0"
  //})

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCjJQ1UO0BgWFnOgtvTinQ4PHzu8FoSVO0",
    version: '3.47'
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => getCars(), 3000);
    return () => clearTimeout(timeout);
  }, [car1Date]);

  function getCars() {
    try {
      axiosInstance.get('/robotaxi/robocar_run/', {
        params: { route_id: routeAppContext.id, section_id: routeSectionAppContext.id }
      }).then(function (response) {
        response.data.map( (data, data_row) => {
          if (data_row == 0) {
            console.log(data);
            setMarkerPosition({
              lat: Number(data.site_lat), 
              lng: Number(data.site_lng)
            });
          }
        });
        setRoboCars(response.data);
        setCar1Date(new Date());
      }).catch(function (error) {
        onError(error);
      });
    } catch (e) {
      onError(e);
    }
  }

  const MarkerCar = (props) => {
    return (
      roboCars && 
      roboCars.map( (data,index) =>
      (
        <Marker
          key={data.id}
          icon={{
            url: `${config.DJANGO_URL}/static/down_img/icon_raxi_kona_front.png`,
            scaledSize: new google.maps.Size(23, 30)
          }}
          position={{ lat: Number(data.site_lat), lng: Number(data.site_lng) }}
          title={data.carname}
        >
        </Marker>
      ))
    );
  }

  const MarkerStrStation = (props) => {
    return (
      strStationAppContext && (<Marker
        position={{ lat: Number(strStationAppContext.station.site_lat), lng: Number(strStationAppContext.station.site_lng) }}
        label={
          {
            fontFamily: 'Fontawesome',
            text: '\uf0aa', //code for font-awesome icon
            fontSize: '16px',
            color: 'white'
          }
        }
        title={strStationAppContext.station.stationname}
        
        >
        </Marker>
      )
    );
  }

  const MarkerEndStation = (props) => {
    return (
      endStationAppContext && (<Marker
        position={{ lat: Number(endStationAppContext.station.site_lat), lng: Number(endStationAppContext.station.site_lng) }}
        label={
          {
            fontFamily: 'Fontawesome',
            text: '\uf0ab', //code for font-awesome icon
            fontSize: '16px',
            color: 'white'
          }
        }
        title={endStationAppContext.station.stationname}
        
        >
        </Marker>
      )
    );
  }

  const onClick = React.useCallback((event) => {
    animateMarkerTo(markerRef.current.marker, event.latLng);
  }, []);

  function animateMarkerTo(marker, newPosition) {
    var options = {
      duration: 5000,
      easing: function (x, t, b, c, d) {
        // jquery animation: swing (easeOutQuad)
        return -c * (t /= d) * (t - 2) + b;
      }
    };
  
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    window.cancelAnimationFrame =
      window.cancelAnimationFrame || window.mozCancelAnimationFrame;
  
    // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    marker.AT_startPosition_lat = marker.getPosition().lat();
    marker.AT_startPosition_lng = marker.getPosition().lng();
    var newPosition_lat = newPosition.lat();
    var newPosition_lng = newPosition.lng();
  
    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
      if (newPosition_lng > marker.AT_startPosition_lng) {
        newPosition_lng -= 360;
      } else {
        newPosition_lng += 360;
      }
    }
  
    var animateStep = function (marker, startTime) {
      var ellapsedTime = new Date().getTime() - startTime;
      var durationRatio = ellapsedTime / options.duration; // 0 - 1
      var easingDurationRatio = options.easing(
        durationRatio,
        ellapsedTime,
        0,
        1,
        options.duration
      );
  
      if (durationRatio < 1) {
        marker.setPosition({
          lat:
            marker.AT_startPosition_lat +
            (newPosition_lat - marker.AT_startPosition_lat) * easingDurationRatio,
          lng:
            marker.AT_startPosition_lng +
            (newPosition_lng - marker.AT_startPosition_lng) * easingDurationRatio
        });
  
        // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
        if (window.requestAnimationFrame) {
          marker.AT_animationHandler = window.requestAnimationFrame(function () {
            animateStep(marker, startTime);
          });
        } else {
          marker.AT_animationHandler = setTimeout(function () {
            animateStep(marker, startTime);
          }, 17);
        }
      } else {
        marker.setPosition(newPosition);
      }
    };
  
    // stop possibly running animation
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.AT_animationHandler);
    } else {
      clearTimeout(marker.AT_animationHandler);
    }
  
    animateStep(marker, new Date().getTime());
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '40vh', margin: 'auto'}}
      center={center}
      zoom={routeAppContext.defalt_zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
      onClick={onClick}
    >
      { /* Child components, such as markers, info windows, etc. */ }
      <MarkerStrStation />
      <MarkerEndStation />
      <Marker ref={markerRef} position={center} />

    </GoogleMap>
  ) : <></>
}

export default GoogleMapReactAPI;