import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RoboCarNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    carname: "",
    car_status: "",
    route: "",
    station: "",
    routesection: "",
    vehicle_id: "",
    icon_src: "",
    persons: "",
    car_number: ""
  });

  const [routeSel, setRouteSel] = useState("");
  const [routesectionSel, setRoutesectionSel] = useState("");
  const [stationSel, setStationSel] = useState("");
  const [statusSel, setStatusSel] = useState("");
  
  const [routeOptions, setRouteOptions] = useState([]);
  const [routesectionOptions, setRoutesectionOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([
    { name: "대기",id: "A"},
    { name: "배차",id: "B"},,
    { name: "수리",id: "C"},
  ]);
  
  useEffect(() => {
    async function onLoad() {

        await axiosInstance.get('/robotaxi/route/',{
          params: {id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const routes = response.data;
          setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
        }).catch(function (error) {
          //console.log(error); 
        });

        await axiosInstance.get('/robotaxi/routesection/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const sections = response.data;
          setRoutesectionOptions(sections.map( data => ({ name: data.sectionname, id: data.id, routesection:data} ) ));
        }).catch(function (error) {
          //console.log(error); 
        });
        
        await axiosInstance.get('/robotaxi/station/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          //console.log(response.data);
          const stations = response.data;
          setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
        }).catch(function (error) {
          //console.log(error); 
        });
        
    }
    
    onLoad();
  }, []);
  
  function validateForm() {
    return (
      fields.carname.length > 0
    );
  }
  
  function createCar(car) {
    console.log(car);
    axiosInstance.post(`/robotaxi/robocar/`, car).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createCar({
        carname: fields.carname,
        car_status: fields.car_status,
        route: fields.route.id,
        routesection: fields.routesection.id,
        station: fields.station.id,
        vehicle_id: fields.vehicle_id,
        icon_src: fields.icon_src,
        persons: fields.persons,
        car_number: fields.car_number
      });
    
      history.push("/robocar");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.route = result.route;
    setRouteSel(event.target.value);
  };

  function onChangeRoutesection(event) {
    const result = routesectionOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.routesection = result.routesection;
    setRoutesectionSel(event.target.value);
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.station = result.station;
    setStationSel(event.target.value);
  };
  
  function onChangeState(event) {
    fields.car_status = event.target.value;
    setStatusSel(event.target.value);
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="carname">
                <Form.Label>차량명</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.carname}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="car_status">
                <DropdownSelector
                        labelName="상태"
                        options={stateOptions}
                        defaultValue={statusSel}
                        onChange={onChangeState}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="구간"
                        options={routeOptions}
                        defaultValue={routeSel}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="routesection">
                <DropdownSelector
                        labelName="노선"
                        options={routesectionOptions}
                        defaultValue={routesectionSel}
                        onChange={onChangeRoutesection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={stationSel}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="vehicle_id">
                <Form.Label>차량 ID</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.vehicle_id}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon경로</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.icon_src}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="persons">
                <Form.Label>탑승인원</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.persons}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="car_number">
                <Form.Label>차량번호</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.car_number}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboCarNew;