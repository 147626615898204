import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavDropdown } from "react-bootstrap";
 
const LangDropdown = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
  const language = [
    { name: "English", value: "en" },
    { name: "Korean", value: "kr" }
  ];
 
  function handleSelect(eventKey) {
    console.log('A key was pressed', eventKey);
    setSelectedLang(eventKey);
    i18n.changeLanguage(eventKey);
  }
  
  return (
    <NavDropdown title={t("LANGUAGE")} id="collasible-nav-dropdown">
        {language.map((language, index) => (
          <NavDropdown.Item
            key={index}
            eventKey={language.value}
            onSelect={(eventKey) => {handleSelect(eventKey)}}
          >
            {language.name}
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  )
}
 
export default LangDropdown;