import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RouteMapNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    sequence: "",
    leadtime: "",
    charge: "",
    routesection: "",
    station: "",
    rundistance: "",
    icon_src: "",
    sectionno: 0,
    drtid: ""
  });
  
  const [routeSectionOptions, setRouteSectionOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  
  useEffect(() => {
    async function onLoad() {
        await axiosInstance.get('/robotaxi/routesection/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          const rtnData = response.data;
          setRouteSectionOptions(rtnData.map( data => ({ name: data.sectionname, id: data.id, section:data} ) ));
        }).catch(function (error) {
          //console.log(error);
        });
        
        await axiosInstance.get('/robotaxi/station/',{
          params: {route_id: routeAppContext.id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          const stations = response.data;
          setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
        }).catch(function (error) {
          //console.log(error);
        });
    }
    
    onLoad();
  }, []);
  
  function validateForm() {
    return (
      true
      //fields.carname.length > 0
    );
  }
  
  function createProc(routemap) {
    axiosInstance.post(`/robotaxi/routemap/`, routemap).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createProc({
        sequence: fields.sequence,
        leadtime: fields.leadtime,
        charge: fields.charge,
        route: routeAppContext.id,
        routesection: fields.routesection.id,
        station: fields.station.id,
        rundistance: fields.rundistance,
        drtid: fields.drtid,
        icon_src: fields.icon_src,
        sectionno: fields.sectionno
      });
    
      history.push("/routemap");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function onChangeRouteSection(event) {
    const result = routeSectionOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.routesection = result.section;
    //this.setState({ selectedValue: event.target.value });
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    fields.station = result.station;
    //this.setState({ selectedValue: event.target.value });
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="sequence">
                <Form.Label>순서</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.sequence}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="노선"
                        options={routeSectionOptions}
                        defaultValue={fields.routesection.id}
                        onChange={onChangeRouteSection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={fields.station.id}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="leadtime">
                <Form.Label>이동시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.leadtime}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>운임(원)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.charge}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="rundistance">
                <Form.Label>이동거리(km)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.rundistance}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon 경로</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.icon_src}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="sectionno">
                <Form.Label>경로 Section</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.sectionno}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="drtid">
                <Form.Label>drtid</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.drtid}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteMapNew;