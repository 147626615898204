// 리다이렉트될 화면
// OAuth2RedirectHandler.js

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "../redux/modules/user";
import { Spinner } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const OAuth2KakaoRedirectHandler = (props) => {
  const dispatch = useDispatch();
  const { userHasAuthenticated } = useAppContext();
  const { setUser } = useAppContext();
  const { routeAppContext } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const { setRouteSectionAppContext } = useAppContext();
  const { setRouteMap } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setLeadtimeAppContext } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  
  // 인가코드
  let code = new URL(window.location.href).searchParams.get("code");
  React.useEffect(async () => {
    dispatch(userActions.KakaoLogin(code, history, userHasAuthenticated, setIsLoading, setUser, routeAppContext, 
      setRouteAppContext, setRouteSectionAppContext, setRouteMap, setCarAppContext, setStrStationAppContext, 
      setEndStationAppContext, setStrSingleDateAppContext, setLeadtimeAppContext));
  }, []);

  return (
    <div>
        <Container>
            <Row className="justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row>
        </Container>
    </div>
  );
};

export default OAuth2KakaoRedirectHandler;