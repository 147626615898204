import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RouteMapInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [routemap, setRoutemap] = useState(null);
  const [sequence, setSequence] = useState("");
  const [leadtime, setLeadtime] = useState("");
  const [rundistance, setRundistance] = useState("");
  const [charge, setCharge] = useState("");
  const [routeSection, setRouteSection] = useState("");
  const [station, setStation] = useState("");
  const [drtid, setDrtid] = useState("");
  const [icon_src, setIcon_src] = useState("");
  const [sectionno, setSectionno] = useState(0);
  
  const [routeSectionOptions, setRouteSectionOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      await axiosInstance.get(`/robotaxi/routemap/${id}/`).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const routemap = response.data;
        setRoutemap(routemap);
        setSequence(routemap.sequence);
        setLeadtime(routemap.leadtime);
        setRundistance(routemap.rundistance);
        setCharge(routemap.charge);
        setRouteSection(routemap.routesection);
        setStation(routemap.station);
        setDrtid(routemap.drtid);
        setIcon_src(station.icon_src);
        setSectionno(station.sectionno);
      }).catch(function (error) {
        //console.log(error);
      });
      
      await axiosInstance.get('/robotaxi/routesection/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        //console.log(response.data);
        const rtnData = response.data;
        setRouteSectionOptions(rtnData.map( data => ({ name: data.sectionname, id: data.id, section:data} ) ));
      }).catch(function (error) {
        //console.log(error);
      });
      
      await axiosInstance.get('/robotaxi/station/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        //console.log(response.data);
        const stations = response.data;
        setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
      }).catch(function (error) {
        //console.log(error);
      });
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      true
      //carname.length > 0
    );
  }
  
  async function saveProc(routemap) {
    console.log(routemap);
    axiosInstance.put(`/robotaxi/routemap/${id}/`, routemap).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          return;
        }).catch(function (error) {
          //console.log(error);
          setIsLoading(false);
        }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveProc({
        id: routemap.id,
        sequence: sequence,
        leadtime: leadtime,
        charge: charge,
        route: routeAppContext.id,
        routesection: routeSection.id,
        station: station.id,
        rundistance: rundistance,
        icon_src: icon_src,
        sectionno: sectionno,
        drtid: drtid
      });
    
      history.push("/routemap");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteProc() {
    return axiosInstance.delete(`/robotaxi/routemap/${id}/`, routemap).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteProc();
      
      history.push("/routemap");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onChangeRouteSection(event) {
    const result = routeSectionOptions.find(option => Number(option.id) === Number(event.target.value));
    setRouteSection(result.section);;
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    setStation(result.station);
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {routemap && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="sequence">
                <Form.Label>순서</Form.Label>
                <Form.Control
                  type="text"
                  value={sequence}
                  onChange={(e) => setSequence(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="노선"
                        options={routeSectionOptions}
                        defaultValue={routeSection.id}
                        onChange={onChangeRouteSection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={station.id}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="leadtime">
                <Form.Label>이동시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={leadtime}
                  onChange={(e) => setLeadtime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>요금(원)</Form.Label>
                <Form.Control
                  type="text"
                  value={charge}
                  onChange={(e) => setCharge(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="rundistance">
                <Form.Label>이동거리(km)</Form.Label>
                <Form.Control
                  type="text"
                  value={rundistance}
                  onChange={(e) => setRundistance(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon경로</Form.Label>
                <Form.Control
                  type="text"
                  value={icon_src}
                  onChange={(e) => setIcon_src(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sectionno">
                <Form.Label>경로 Section</Form.Label>
                <Form.Control
                  type="text"
                  value={sectionno}
                  onChange={(e) => setSectionno(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="drtid">
                <Form.Label>drtid</Form.Label>
                <Form.Control
                  type="text"
                  value={drtid}
                  onChange={(e) => setDrtid(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteMapInfo;