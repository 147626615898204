import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import config from "../config";

function TestSystem() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isBillingKey, setIsBillingKey] = useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));

  const [bookDate, setBookDate] = useState('');

  const { t } = useTranslation();
  
  useEffect(() => { 
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      axiosInstance.get('/robotaxi/basicinfo/').then(function(response) {
        //console.log(response.data.current_datetime);
        setBookDate(response.data.current_datetime);
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        } else {
          onError(t('PROCESS_FAIL'));
        }
      });
    } catch (e) {
      onError(t('PROCESS_FAIL'));
    }
  }

  async function handleCardRegister(event) {
    event.preventDefault();
    var IMP = window.IMP;
    IMP.init("imp82530589");

    // customer_uid = email + yyyymmdd
    // merchant_uid = datetime
    var merchant_uid = bookDate;
    var customer_uid = tempUser.email + '_card';

    //IOS webview
    const isIOS = (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));

    if(isIOS) {
        try {
            const params = {
              userCode : "imp82530589",                                   // 가맹점 식별코드
              iamPortRequest : { // param
                pg : 'jtnet.sonnet001m',
                pay_method : 'card', // 'card'만 지원됩니다.
                merchant_uid: merchant_uid, // 상점에서 관리하는 주문 번호
                name : '최초인증결제',
                amount : 0, // 빌링키 발급만 진행하며 결제승인을 하지 않습니다.
                customer_uid : customer_uid, // 필수 입력.
                buyer_email : tempUser.email,
                buyer_name : '',
                buyer_tel : '',
                app_scheme: 'iamport', // 가맹점 앱의 URL scheme
                m_redirect_url : `${config.REDIRECT_URI_JTNET}`
              },                                 // 결제 데이터
            };
            window.webkit.messageHandlers.iamportmobilewebmode.postMessage(params)
        } catch (error) {
          console.error(error);
        }
    } 
    // IMP.request_pay(param, callback) 호출
    //console.log(customer_uid);
    IMP.request_pay({ // param
        pg : 'jtnet.sonnet001m',
        pay_method : 'card', // 'card'만 지원됩니다.
        merchant_uid: merchant_uid, // 상점에서 관리하는 주문 번호
        name : '최초인증결제',
        amount : 0, // 빌링키 발급만 진행하며 결제승인을 하지 않습니다.
        customer_uid : customer_uid, // 필수 입력.
        buyer_email : tempUser.email,
        buyer_name : '',
        buyer_tel : '',
        app_scheme: 'iamport', // 가맹점 앱의 URL scheme
        m_redirect_url : `${config.REDIRECT_URI_JTNET}`
        }, function (rsp) { // callback
        if (rsp.success) {
            console.log("success:" + customer_uid);
            // 빌링키 발급 성공
            // update Customeruser : user_type = customer_uid, status = CARD
            axiosInstance.put(`/accounts/user/${tempUser.pk}/`, {
                email: userDetail.email,
                nickname: userDetail.nickname,
                phone_number: userDetail.phone_number,
                is_staff: userDetail.is_staff,
                is_active: userDetail.is_active,
                user_type: tempUser.email + '_card',
                status: 'CARD',
                route_default: userDetail.route_default
            }).then(function(response) {
                onError("정상적으로 등록되었습니다");
                setIsBillingKey(true);
                setIsLoading(false);
                onLoad();
            }).catch(function (error) {
            if (error && error.response && error.response.status) {
                if ( error.response.status === 444 ) {
                onError(error.response.data.message);
                } else {
                console.log(error);
                }
            } else {
                console.log(error);
            }
            
                //onError(t('PROCESS_FAIL'));
                setIsLoading(false);
            }); 
        }
    })

  }

  async function handleCardDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    
    axiosInstance.put(`/accounts/user/${tempUser.pk}/`, {
        email: userDetail.email,
        nickname: userDetail.nickname,
        phone_number: userDetail.phone_number,
        is_staff: userDetail.is_staff,
        is_active: userDetail.is_active,
        user_type: 'GUEST',
        status: 'NORMAL',
        route_default: userDetail.route_default
    }).then(function(response) {
        alert("정상적으로 처리되었습니다");
        setIsBillingKey(false);
        setIsLoading(false);
        onLoad();
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          console.log(error);
        }
      } else {
        console.log(error);
      }
        //onError(t('PROCESS_FAIL'));
        setIsLoading(false);
    }); 

  }

  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        시스템 테스트화면
      </div>
      <div>
        <Form >
            <LoaderButton
                block
                size="lg"
                variant="primary"
                onClick={handleCardRegister}
                isLoading={isLoading}
                disabled={isBillingKey}
            >
            {t('CARDSAVE')}
            </LoaderButton>
            <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleCardDelete}
                isLoading={isDeleting}
                disabled={!isBillingKey}
            >
            {t('CARDDELETE')}
            </LoaderButton>
        </Form>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default TestSystem;