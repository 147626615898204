import { createAction, handleActions } from 'redux-actions';

const CODE = 'login/CODE';

export const code = createAction(CODE);

const initialState = {
    route_id : '1',
    is_publish: true
}

export default handleActions( {
    [CODE]: (state, action) => {
        console.log(action.payload.route);
        return { route_id: action.payload.route.id, is_publish: action.payload.route.is_publish };
    },
}, initialState);