import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function RoboTimeTableInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  const [robotimetable, setRobotimetable] = useState(null);
  const [timehour, setTimehour] = useState("");
  const [timeminute, setTimeminute] = useState("");
  const [route, setRoute] = useState("");
  const [routesection, setRoutesection] = useState("");
  const [station, setStation] = useState("");
  
  const [routeSel, setRouteSel] = useState("");
  const [routesectionSel, setRoutesectionSel] = useState("");
  const [stationSel, setStationSel] = useState("");
  
  const [routeOptions, setRouteOptions] = useState([]);
  const [routesectionOptions, setRoutesectionOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      await axiosInstance.get(`/robotaxi/robotimetable/${id}/`).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const robotimetable = response.data;
        setRobotimetable(robotimetable);
        setTimehour(robotimetable.time_hour);
        setTimeminute(robotimetable.time_minute);
        setRoute(robotimetable.route);
        setRouteSel(robotimetable.route.id);
        setRoutesection(robotimetable.routesection);
        setRoutesectionSel(robotimetable.routesection.id);
        if (robotimetable.station) {
          setStation(robotimetable.station);
          setStationSel(robotimetable.station.id);
        } else {
          setStation("");
          setStationSel("");
        }
        
      }).catch(function (error) {
        console.log(error);
      });
      
      await axiosInstance.get('/robotaxi/route/',{
        params: {id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const routes = response.data;
        setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
      }).catch(function (error) {
        console.log(error);
      });

      await axiosInstance.get('/robotaxi/routesection/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const sections = response.data;
        setRoutesectionOptions(sections.map( data => ({ name: data.sectionname, id: data.id, routesection:data} ) ));
      }).catch(function (error) {
        console.log(error);
      });
      
      await axiosInstance.get('/robotaxi/station/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const stations = response.data;
        setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
      }).catch(function (error) {
        console.log(error);
      });
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      true
      //carname.length > 0
    );
  }
  
  async function saveRobotimetable(robotimetable) {
    console.log(robotimetable);
    axiosInstance.put(`/robotaxi/robotimetable/${id}/`, robotimetable).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      console.log(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveRobotimetable({
        id: robotimetable.id,
        time_hour: timehour,
        time_minute: timeminute,
        route: route.id,
        station: station.id,
        routesection: routesection.id
      });
    
      history.push("/robotimetable");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteRobotimetable() {
    return axiosInstance.delete(`/robotaxi/robotimetable/${id}/`, robotimetable).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      console.log(error);
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this robotimetable?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteRobotimetable();
      
      history.push("/robotimetable");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoute(result.route);;
    //this.setState({ selectedValue: event.target.value });
    setRouteSel(event.target.value);
  };

  function onChangeRoutesection(event) {
    const result = routesectionOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoutesection(result.routesection);;
    //this.setState({ selectedValue: event.target.value });
    setRoutesectionSel(event.target.value);
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    setStation(result.station);
    //this.setState({ selectedValue: event.target.value });
    setStationSel(event.target.value);
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {robotimetable && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="구간"
                        options={routeOptions}
                        defaultValue={routeSel}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="routesection">
                <DropdownSelector
                        labelName="노선"
                        options={routesectionOptions}
                        defaultValue={routesectionSel}
                        onChange={onChangeRoutesection}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={stationSel}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="timehour">
                <Form.Label>시(hour)</Form.Label>
                <Form.Control
                  type="text"
                  value={timehour}
                  onChange={(e) => setTimehour(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="timeminute">
                <Form.Label>분(minute)</Form.Label>
                <Form.Control
                  type="text"
                  value={timeminute}
                  onChange={(e) => setTimeminute(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboTimeTableInfo;