import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import TableDateRange from "./TableDateRange";
import * as dateFns from "date-fns";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAppContext } from "../libs/contextLib";

function MessageStore() {
  const [messageStore, setMessageStore] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date()); 
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { routeAppContext } = useAppContext();
  
  const columns = [
    {
        dataField: "msg_title",
        text: '메시지명',
    },
    {
        dataField: "msg_body",
        text: '메지시내용'
    },
    {
        dataField: "crt_date",
        text: '발송시간'
    }
  ];
  
  const MyExportData = (props) => {
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ props.onClick } />
            {" "}To {props.type}
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  useEffect(() => {
    onLoad();
  }, [startDate, endDate]);
  
  async function onLoad() {
    setMessageStore([]);
    //console.log(dateFns.format(startDate,"yyyy-MM-dd'T'00:00:00"));
    //console.log(dateFns.format(dateFns.add(endDate, { days: 1 }),"yyyy-MM-dd'T'00:00:00"));
    try {
      setIsLoading(true);
      axiosInstance.get('/robotaxi/messagestore/', {
          params:{ 
              s_date:dateFns.format(startDate,"yyyy-MM-dd'T'00:00:00"),
              e_date:dateFns.format(dateFns.add(endDate, { days: 1 }),"yyyy-MM-dd'T'00:00:00"),
          }
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          let filename;
          response.data.map( (data_row) => {
              let msg = {};
              
              msg['msg_title'] = data_row.msg_title
              msg['msg_body'] = data_row.msg_body
              msg['crt_date'] = moment(new Date(data_row.crt_date)).format("yyyy-MM-DD HH:mm:ss")
              setMessageStore(messageStore => [...messageStore, msg])
              
              filename = '메시지내역 '.concat('기간(',dateFns.format(startDate,"yyyy-MM-dd"),'~',dateFns.format(endDate,"yyyy-MM-dd"),')')
          });
          setFileName(filename);
          setIsLoading(false);
      }).catch(function (error) {
        setIsLoading(false);
      }); 
    } catch (e) {
      onError(e);
    }
  }

  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob(["\ufeff"+data], { type: fileType + '; charset=utf-8'})
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(messageStore),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['메시지명,메시지내용,발송시간']
  
    // Convert users data to a csv
    let booksCsv = messageStore.reduce((acc, book) => {
      const { msg_title, msg_body, crt_date } = book
      acc.push([msg_title, msg_body, crt_date].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.csv'),
      fileType: 'text/csv',
    })
  }

  const exportToTxt = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['메시지명|메시지내용|발송시간']
  
    // Convert users data to a csv
    let booksCsv = messageStore.reduce((acc, book) => {
      const { msg_title, msg_body, crt_date } = book
      acc.push([msg_title, msg_body, crt_date].join('|'))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.txt'),
      fileType: 'text/plain',
    })
  }

  async function onChangeStartDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(endDate, new Date(event)) ) {
        setStartDate(startDate => event);
    } else {
        alert("시작일자는 종료일자보다 같거나 작아야 합니다.");
        setStartDate(startDate => new Date());
    }
  }

  async function onChangeEndDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(new Date(event), startDate) ) {
        setEndDate(endDate => event);
    } else {
        alert("종료일자는 시작일자보다 같거나 커야 합니다.");
        setEndDate(endDate => new Date());
    }
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        <div>
        <Container>
            <Row xs="auto" className="align-items-center">
                <Col>
                    <TableDateRange
                        wrapperClassName="datepicker"
                        selectedStartDate={startDate}
                        onChangeStartDate={onChangeStartDate} 
                        selectedEndDate={endDate}
                        onChangeEndDate={onChangeEndDate} 
                    />
                </Col> 
            </Row>
            <Row xs="auto" className="align-items-center">
                <Col />
                <Col>
                    <MyExportData onClick={exportToCsv} type='CSV' />
                </Col>
                <Col>
                    <MyExportData onClick={exportToTxt} type='TXT' />
                </Col>
            </Row>
        </Container>
        {isLoading ? <LoadingSpinner /> : ""}
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={messageStore}         
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            pagination={paginationFactory(options)}
        />
        </div>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default MessageStore;