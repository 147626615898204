import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';

function RoboTimeTable() {
  const { t } = useTranslation();
  const [robotimetables, setRobotimetables] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { routeAppContext } = useAppContext();
  
  const columns = [
    {
      dataField: "sectionname",
      text: "노선"
    },
    {
      dataField: "stationname",
      text: "정류장"
    },
    {
        dataField: "timehour",
        text: "시"
    },
    {
        dataField: "timeminute",
        text: "분"
    }
  ];
  
  useEffect(() => {
    async function onLoad() {
      axiosInstance.get('/robotaxi/robotimetable/',{
        params: {route_id: routeAppContext.id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRobotimetables(JSON.parse(response.data));
      }).catch(function (error) {
        console.log(error);
      }); 
    }

    onLoad();
  }, []);
  
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
        //console.log(`clicked on row with index: ${rowIndex}`);
        history.push(`/robotimetable/${row['id']}`);
    }
  };
  
  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
     bgColor: 'skyblue'
  };
  
  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        노선시간표
      </div>
      <div className="bg-light border">
          <LinkContainer to="/robotimetable/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New RoboTimeTable</span>
          </ListGroup.Item>
        </LinkContainer>
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={robotimetables}
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            rowEvents={ rowEvents }
            pagination={paginationFactory(options)}
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboTimeTable;