import React from "react";
import { Container, Row, Col, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import * as FaIcons from "react-icons/fa";
import "./SubNavigation.css";

function SubNavigationUser() {
    const { t } = useTranslation();
    const activeStyle = {
        background: 'grey',
        color: 'white'
    }
    return (

        <div className="bg-light my-1" >
            <Nav fill variant="pills">
                {/*
                <LinkContainer to="/" exact={true} className="nav-link"  style={{ cursor: 'pointer' }} isActive={(match, location)=>{
                    //console.log(location);
                    if(match){
                        //console.log(match);
                    }
                    return match;
                }} >
                    <div>
                        <span><FaIcons.FaHome size={20} /></span>
                    </div>
                </LinkContainer>
                */}
                <Container>
                    <Row>
                        <Col>
                            <LinkContainer to="/bookingstation" className="nav-link mx-2" style={{ cursor: 'pointer' }}>
                                <strong>{t('BOOKING')}</strong>
                            </LinkContainer>
                        </Col>
                        <Col>
                            <LinkContainer to="/bookingcar" className="nav-link mx-2" style={{ cursor: 'pointer' }}>
                                <strong>{t('BOOKINGING')}</strong>
                            </LinkContainer>
                        </Col>
                    </Row>
                </Container>
                
                {/*
                <LinkContainer to="/boardingqrscan" className="nav-link" style={{ cursor: 'pointer' }}>
                    <strong>{t('BORARDINGSCAN')}</strong>
                </LinkContainer>
                
                <LinkContainer to="/userdetail" className="nav-link" style={{ cursor: 'pointer' }}>
                    <strong>{t('USERDETAIL')}</strong>
                </LinkContainer>
                */}
            </Nav>
        </div>

    );
}

export default SubNavigationUser;