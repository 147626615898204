import React, { useState, useCallback } from "react";
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { TwitterPicker } from 'react-color';
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";
import TableTimePicker from "./TableTimePicker";
import ToggleSwitch from "../components/ToggleSwitch";
import * as dateFns from "date-fns";

function RouteSectionNew() {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { routeAppContext } = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        sectionname: "",
        mapurl: "",
        cmsid: "",
        routegubun: "",
        charge: "",
        persons: "",
        sparetime: "60",
        drtid: ""
    });
    const [sectioncolor, setSectioncolor] = useState("");
    const [is_publish, setIs_publish] = useState(false);
    const [is_pay, setIs_pay] = useState(false);
    const [is_round, setIs_round] = useState(false);
    const [is_goworkhome, setIs_goworkhome] = useState(false);
    const [goworkfrom, setGoworkfrom] = useState(new Date());
    const [goworkto, setGoworkto] = useState(new Date());
    const [gohomefrom, setGohomefrom] = useState(new Date());
    const [gohometo, setGohometo] = useState(new Date());

    const onSwitchActionPublish = () => {
        setIs_publish(!is_publish);
        //console.log(isSwitchOnPrivate);
    };

    const onSwitchActionPay = () => {
        setIs_pay(!is_pay);
        //console.log(isSwitchOnPrivate);
    };

    const onSwitchActionRound = () => {
        setIs_round(!is_round);
        //console.log(isSwitchOnPrivate);
    };

    const onSwitchActionGoWorkHome = () => {
        setIs_goworkhome(!is_goworkhome);
        //console.log(isSwitchOnPrivate);
    };

    function onChangeGoworkfrom(event) {
        console.log(event);
        setGoworkfrom(event);
    }

    function onChangeGoworkto(event) {
        setGoworkto(event);
    }

    function onChangeGohomefrom(event) {
        setGohomefrom(event);
    }

    function onChangeGohometo(event) {
        setGohometo(event);
    }

    function validateForm() {
        return (
            fields.sectionname.length > 0 &&
            fields.mapurl.length > 0 &&
            sectioncolor.length > 0
        );
    }

    function createProc(section) {
        axiosInstance.post(`/robotaxi/routesection/`, section).then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            return;
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            createProc({
                route: routeAppContext,
                sectionname: fields.sectionname,
                mapurl: fields.mapurl,
                sectioncolor: sectioncolor,
                is_publish: is_publish,
                is_pay: is_pay,
                is_round: is_round,
                cmsid: fields.cmsid,
                routegubun: fields.routegubun,
                charge: fields.charge,
                persons: fields.persons,
                is_goworkhome: is_goworkhome,
                goworkfrom: dateFns.format(goworkfrom, "yyyy-MM-dd'T'HH:mm:00"),
                goworkto: dateFns.format(goworkto, "yyyy-MM-dd'T'HH:mm:00"),
                gohomefrom: dateFns.format(gohomefrom, "yyyy-MM-dd'T'HH:mm:00"),
                gohometo: dateFns.format(gohometo, "yyyy-MM-dd'T'HH:mm:00"),
                sparetime: fields.sparetime,
                drtid: fields.drtid
            });

            history.push("/routesection");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    const handleColorChange = useCallback(
        (color) => {
            setSectioncolor(color.hex);
        }, [sectioncolor])

    return (
        <Stack gap={3}>
            <div className="bg-light border">

                <div className="Notes">
                    <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="sectionname">
                            <Form.Label>섹션명</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.sectionname}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="mapurl">
                            <Form.Label>섹션맵 URL</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.mapurl}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sectioncolor">
                            <Form.Label>색상(HEX)</Form.Label>
                            <Form.Control
                                type="text"
                                value={sectioncolor}
                                disabled={true}
                                onChange={handleColorChange}
                            />
                            <TwitterPicker
                                color={sectioncolor}
                                onChangeComplete={handleColorChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="cmsid">
                            <Form.Label>CMS Route ID</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.cmsid}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="routegubun">
                            <Form.Label>노선운영구분(예약호출택시 : A, 고정노선버스 : B )</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.routegubun}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="charge">
                            <Form.Label>운임(운영구분A: 건당, 운영구분B: 인당)</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.charge}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="persons">
                            <Form.Label>우선순위</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.persons}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sparetime">
                            <Form.Label>여유시간(분)</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.sparetime}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="goworkhome">
                            <Form.Label><span><i class="fas fa-angle-double-right"></i> 출퇴근설정</span></Form.Label>
                            <Container>
                                <Row xs="auto">
                                    <Col>
                                        <ToggleSwitch label="출퇴근적용" isChecked={is_goworkhome} onChange={onSwitchActionGoWorkHome} />
                                    </Col>
                                </Row>
                                {is_goworkhome &&
                                    <Container>
                                        <Row>
                                            <Form.Label>출근시간</Form.Label>
                                            <Col>
                                                <TableTimePicker
                                                    wrapperClassName="datepicker"
                                                    selected={goworkfrom}
                                                    onChange={onChangeGoworkfrom}
                                                />
                                            </Col>
                                            <Col>
                                                <TableTimePicker
                                                    wrapperClassName="datepicker"
                                                    selected={goworkto}
                                                    onChange={onChangeGoworkto}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label>퇴근시간</Form.Label>
                                            <Col>
                                                <TableTimePicker
                                                    wrapperClassName="datepicker"
                                                    selected={gohomefrom}
                                                    onChange={onChangeGohomefrom}
                                                />
                                            </Col>
                                            <Col>
                                                <TableTimePicker
                                                    wrapperClassName="datepicker"
                                                    selected={gohometo}
                                                    onChange={onChangeGohometo}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Container>
                        </Form.Group>
                        <Form.Group controlId="subject">
                            <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                            <Container>
                                <Row xs="auto">
                                    <Col>
                                        <ToggleSwitch label="운행" isChecked={is_publish} onChange={onSwitchActionPublish} />
                                    </Col>
                                    <Col>
                                        <ToggleSwitch label="유료" isChecked={is_pay} onChange={onSwitchActionPay} />
                                    </Col>
                                    <Col>
                                        <ToggleSwitch label="예약" isChecked={is_round} onChange={onSwitchActionRound} />
                                    </Col>
                                </Row>
                            </Container>
                        </Form.Group>
                        <Form.Group controlId="drtid">
                            <Form.Label>drtid</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.drtid}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            variant="primary"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Create
                        </LoaderButton>
                    </Form>
                </div>

            </div>
            <div>
                <br /><br /><br />
            </div>
        </Stack>
    );
}

export default RouteSectionNew;