// redux > modules > user.js
import React, { useState } from "react";
import axios from 'axios'
import axiosInstance from "../../axiosApi";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import { setToken } from "../../firebase";

const KakaoLogin = (code, history, userHasAuthenticated, setIsLoading, setUser, routeAppContext, setRouteAppContext, setRouteSectionAppContext, setRouteMap, setCarAppContext, setStrStationAppContext, setEndStationAppContext, setStrSingleDateAppContext, setLeadtimeAppContext) => {
  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`, {
      params: { route_id: route_id, is_publish: '1' }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`, {
        params: { id: route_id, section: section_id }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`, {
          params: { route_id: route_id, section_id: section_id }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
        console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/', {
      params: { route_id: route_id }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/', {
          params: { id: route_id, section: response.data.routesection.id }
        }).then(function (response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }

  return function (dispatch, getState) {
    axios.get(`${config.DJANGO_URL}/accounts/kakao/callback/react/`,
      {
        params: {
          code: code,
        },
      }
    ).then((response) => {
      //get token
      let user = null;
      var route_id = "";
      var section_id = "";
      axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('access_user', JSON.stringify(response.data.user));
      setUser(response.data.user);
      userHasAuthenticated(true);
      setToken('robotaxi', response.data.user.email);
      user = response.data.user;
      //console.log(routeAppContext);
      axiosInstance.get('/robotaxi/route/').then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        if (routeAppContext) {
          initialPostSetting(routeAppContext.id);
        } else {
          let idx = 0;
          let apply_route = null;
          response.data.map((data_row) => {
            if (data_row.is_publish && data_row.id == user.route_default) {
              apply_route = data_row;
              setRouteAppContext(data_row);
              idx++;
            }
          });

          if (idx == 0) {
            apply_route = response.data[0];
            setRouteAppContext(response.data[0]);
          }

          route_id = apply_route.id;
          initialPostSetting(route_id);
        }

      }).catch(function (error) {
        console.log(error.response);
      });

      history.push("/bookingstation");

      //window.alert("Login success...");
      //get token -> change page to HOME
      //history.replace("/"); 

    }).catch((error) => {
      //console.log(error.response);
      onError(error.response.data);
      userHasAuthenticated(false);
      setIsLoading(false);
      history.push("/login");
    });
  }
};

const KakaoSignup = (code, history, userHasAuthenticated, setIsLoading, setUser, routeAppContext, setRouteAppContext, setRouteSectionAppContext, setRouteMap, setCarAppContext, setStrStationAppContext, setEndStationAppContext, setStrSingleDateAppContext, setLeadtimeAppContext) => {
  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`, {
      params: { route_id: route_id, is_publish: '1' }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`, {
        params: { id: route_id, section: section_id }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`, {
          params: { route_id: route_id, section_id: section_id }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
        console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/', {
      params: { route_id: route_id }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/', {
          params: { id: route_id, section: response.data.routesection.id }
        }).then(function (response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }
  return function (dispatch, getState) {
    axios.get(`${config.DJANGO_URL}/accounts/kakao/callback/react/signup/`,
      {
        params: {
          code: code,
        },
      }
    ).then((response) => {
      //get token
      let user = null;
      var route_id = "";
      var section_id = "";
      axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('access_user', JSON.stringify(response.data.user));
      setUser(response.data.user);
      userHasAuthenticated(true);
      setToken('robotaxi', response.data.user.email);
      user = response.data.user;
      //console.log(routeAppContext);
      axiosInstance.get('/robotaxi/route/').then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        if (routeAppContext) {
          initialPostSetting(routeAppContext.id);
        } else {
          let idx = 0;
          let apply_route = null;
          response.data.map((data_row) => {
            if (data_row.is_publish && data_row.id == user.route_default) {
              apply_route = data_row;
              setRouteAppContext(data_row);
              idx++;
            }
          });

          if (idx == 0) {
            apply_route = response.data[0];
            setRouteAppContext(response.data[0]);
          }

          route_id = apply_route.id;
          initialPostSetting(route_id);
        }

      }).catch(function (error) {
        console.log(error.response);
      });

      history.push("/bookingstation");

      //window.alert("Login success...");
      //get token -> change page to HOME
      //history.replace("/"); 

    }).catch((error) => {
      //console.log(error.response);
      onError(error.response.data);
      userHasAuthenticated(false);
      setIsLoading(false);
      history.push("/signup");
    });
  }
};

const GoogleLogin = (code, history, userHasAuthenticated, setIsLoading, setUser, routeAppContext, setRouteAppContext, setRouteSectionAppContext, setRouteMap, setCarAppContext, setStrStationAppContext, setEndStationAppContext, setStrSingleDateAppContext, setLeadtimeAppContext) => {
  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`, {
      params: { route_id: route_id, is_publish: '1' }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`, {
        params: { id: route_id, section: section_id }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`, {
          params: { route_id: route_id, section_id: section_id }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
        console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/', {
      params: { route_id: route_id }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/', {
          params: { id: route_id, section: response.data.routesection.id }
        }).then(function (response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }
  return function (dispatch, getState) {
    axios.get(`${config.DJANGO_URL}/accounts/google/callback/react/`,
      {
        params: {
          code: code,
        },
      }
    ).then((response) => {
      //get token
      let user = null;
      var route_id = "";
      var section_id = "";
      axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('access_user', JSON.stringify(response.data.user));
      setUser(response.data.user);
      userHasAuthenticated(true);
      setToken('robotaxi', response.data.user.email);
      user = response.data.user;
      //console.log(routeAppContext);
      axiosInstance.get('/robotaxi/route/').then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        if (routeAppContext) {
          initialPostSetting(routeAppContext.id);
        } else {
          let idx = 0;
          let apply_route = null;
          response.data.map((data_row) => {
            if (data_row.is_publish && data_row.id == user.route_default) {
              apply_route = data_row;
              setRouteAppContext(data_row);
              idx++;
            }
          });

          if (idx == 0) {
            apply_route = response.data[0];
            setRouteAppContext(response.data[0]);
          }

          route_id = apply_route.id;
          initialPostSetting(route_id);
        }

      }).catch(function (error) {
        console.log(error.response.data);
      });

      history.push("/bookingstation");

      //window.alert("Login success...");
      //get token -> change page to HOME
      //history.replace("/"); 

    }).catch((error) => {
      //console.log(error.response);
      onError(error.response.data);
      userHasAuthenticated(false);
      setIsLoading(false);
      history.push("/login");
    });
  }
};

const GoogleSignup = (code, history, userHasAuthenticated, setIsLoading, setUser, routeAppContext, setRouteAppContext, setRouteSectionAppContext, setRouteMap, setCarAppContext, setStrStationAppContext, setEndStationAppContext, setStrSingleDateAppContext, setLeadtimeAppContext) => {
  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`, {
      params: { route_id: route_id, is_publish: '1' }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`, {
        params: { id: route_id, section: section_id }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`, {
          params: { route_id: route_id, section_id: section_id }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
        console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/', {
      params: { route_id: route_id }
    }).then(function (response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/', {
          params: { id: route_id, section: response.data.routesection.id }
        }).then(function (response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
          history.push("/bookingstation");
        }).catch(function (error) {
          console.log(error);
        });
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }
  return function (dispatch, getState) {
    axios.get(`${config.DJANGO_URL}/accounts/google/callback/react/signup/`,
      {
        params: {
          code: code,
        },
      }
    ).then((response) => {
      //get token
      let user = null;
      var route_id = "";
      var section_id = "";
      axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('access_user', JSON.stringify(response.data.user));
      setUser(response.data.user);
      userHasAuthenticated(true);
      setToken('robotaxi', response.data.user.email);
      user = response.data.user;
      //console.log(routeAppContext);
      axiosInstance.get('/robotaxi/route/').then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        if (routeAppContext) {
          initialPostSetting(routeAppContext.id);
        } else {
          let idx = 0;
          let apply_route = null;
          response.data.map((data_row) => {
            if (data_row.is_publish && data_row.id == user.route_default) {
              apply_route = data_row;
              setRouteAppContext(data_row);
              idx++;
            }
          });

          if (idx == 0) {
            apply_route = response.data[0];
            setRouteAppContext(response.data[0]);
          }

          route_id = apply_route.id;
          initialPostSetting(route_id);
        }

      }).catch(function (error) {
        console.log(error.response.data);
      });

      history.push("/bookingstation");

      //window.alert("Login success...");
      //get token -> change page to HOME
      //history.replace("/"); 

    }).catch((error) => {
      //console.log(error.response);
      onError(error.response.data);
      userHasAuthenticated(false);
      setIsLoading(false);
      history.push("/signup");
    });
  }
};

const actionCreators = { KakaoLogin, KakaoSignup, GoogleLogin, GoogleSignup };

export { actionCreators }