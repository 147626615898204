import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function StationNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { routeAppContext } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    stationname: "",
    site_lat: "",
    site_lng: "",
    cmsid: "",
    icon_src: "",
    sectionno: 0
  });
  
  function validateForm() {
    return (
      fields.stationname.length > 0 &&
      fields.site_lat.length > 0 &&
      fields.site_lng.length > 0
    );
  }
  
  function createStation(station) {
    axiosInstance.post(`/robotaxi/station/`, station).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
          setIsLoading(false);
        }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createStation({
        stationname: fields.stationname,
        site_lat: fields.site_lat,
        site_lng: fields.site_lng,
        route: routeAppContext,
        cmsid: fields.cmsid,
        icon_src: fields.icon_src,
        sectionno: fields.sectionno
      });
    
      history.push("/station");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="stationname">
                <Form.Label>정류장명</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.stationname}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="site_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.site_lat}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="site_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.site_lng}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="cmsid">
                <Form.Label>CMS Station ID</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.cmsid}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon 경로</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.icon_src}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="sectionno">
                <Form.Label>경로 Section</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.sectionno}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default StationNew;