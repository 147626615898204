import React, { useState, useEffect } from "react";
import { Accordion, Card, Button, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useHistory } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { FaStopCircle } from "react-icons/fa";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';

function RouteMapCard(props) {
    const { t } = useTranslation();
    const { setRouteAppContext } = useAppContext();
    const { user } = useAppContext();
    const { routeMap, setRouteMap } = useAppContext();
    const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
    const { setCarAppContext } = useAppContext();
    const history = useHistory();

    const Viewer = ({content}) => (
        <div
          className="ck-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      );

    const onClickHandler = (param, event) => {
        //console.log(history.location.pathname);
        //setStrStationAppContext(param);
        //console.log(param);
        setRouteAppContext(param);
        axiosInstance.get('/robotaxi/routemap/',{
            params: {id: param.id}
        }).then(function(response) {
            setRouteMap(response.data);
            axiosInstance.get('/robotaxi/robocar_run/',{
                params: {route_id: param.id, section_id: routeSectionAppContext.id}
              }).then(function(response) {
                setCarAppContext(response.data[0]);
                history.push("/bookingstation");
              }).catch(function (error) {
                console.log(error);
              });
        }).catch(function (error) {
            console.log(error);
        });
    };

    return (
        <>
            {props.route.map((data) => {
                if (data.is_publish || user.is_staff) {
                    return (
                        <Card>
                            <Card.Header style={{ backgroundColor: "#706C61", color: "#ffffff" }}>
                                <Badge bg="success" style={{ marginRight: 10 }}>{data.id}</Badge>{' '}{data.routename}{' '}{'('}{data.is_pay ? t('PAID') : t('FREE')}{')'}
                            </Card.Header>
                            <Card.Body>
                                <Viewer content={data.content} />
                                <Button style={{ backgroundColor: "#255F85", color: "#ffffff" }} onClick={(e) => onClickHandler(data, e)}>
                                    <FaStopCircle />{' '}{t('GOBOOKING')}
                                </Button>
                            </Card.Body>
                        </Card>
                    );
                }
            })}
        </>
    );
}

export default RouteMapCard;