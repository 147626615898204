import React, { useState } from "react";
import { Nav, Navbar, Stack, Form, Accordion, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { setToken } from "../firebase";
import config from "../config";
import TermsOfService from "../components/TermsOfService";
import TermsOfPrivacy from "../components/TermsOfPrivacy";
import ToggleSwitch from "../components/ToggleSwitch";
import styled from "styled-components";
import kakaoLogo from "../images/kakao_logo.png";
import appleLogo from "../images/apple_icon.png";
import googleLogo from "../images/g-logo.png";
import { KAKAO_AUTH_URL_SIGNUP } from "../components/OAuthKakaoSignup";
import { GOOGLE_AUTH_URL_SIGNUP } from "../components/OAuthGoogleSignup";
import AppleLogin from 'react-apple-login'

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const NaverShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;
  
const KakaoShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;

const KakaoIcon = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 24px;
  `;

export default function Signup() {
  const { t } = useTranslation();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "OK",
    phone_number: "",
    authcode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(true);
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isSwitchOnService, setIsSwitchOnService] = useState(false);
  const [isSwitchOnPrivate, setIsSwitchOnPrivate] = useState(false);
  const [isSwitchOnAgeLimit, setIsSwitchOnAgeLimit] = useState(false);
  const { routeAppContext } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const { setRouteSectionAppContext } = useAppContext();
  const { setRouteMap } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setLeadtimeAppContext } = useAppContext();

  const onSwitchActionService = () => {
    setIsSwitchOnService(!isSwitchOnService);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPrivate = () => {
    setIsSwitchOnPrivate(!isSwitchOnPrivate);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionAgeLimit = () => {
    setIsSwitchOnAgeLimit(!isSwitchOnAgeLimit);
    //console.log(isSwitchOnAgeLimit);
  };

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      isConfirm &&
      isSwitchOnService &&
      isSwitchOnPrivate &&
      isSwitchOnAgeLimit
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isConfirm) {
      setIsLoading(true);
      axios.post(`${config.DJANGO_URL}/accounts/checksignup/`, {
                authcode: fields.authcode,
      }).then(function(response) {
        if (response.data.access === 'ok') {
          setIsConfirm(true);
          setMessage(t('AUTHOKMSG'));
        } else {
          setMessage(t('AUTHFAILMSG'));
        }
        setIsLoading(false);
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('AUTHFAILMSG'));
          }
        } else {
          onError(t('AUTHFAILMSG'));
        }
        
        userHasAuthenticated(false);
        setIsLoading(false);
      });
    } else {
      //1. email check
      axios.post(`${config.DJANGO_URL}/accounts/checklogin/`, {
        email: fields.email,
        password1: fields.password,
        password2: fields.confirmPassword
      }).then( function(response) {
        //console.log(response.data);
        onError("등록된 회원입니다. 이메일을 다시 확인해 주세요");
        //1. email check error
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 445 ) {
            setIsLoading(true);
            //2. 회원등록
            axios.post(`${config.DJANGO_URL}/dj-rest-auth/registration/`, {
              email: fields.email,
              password1: fields.password,
              password2: fields.confirmPassword
            }).then( function(response) {
              //console.log(response.data);
              setMessage(response.data.detail);
              setNewUser("test");
              setIsLoading(false);
            }).catch(function(error) {
              //console.log(error.response.data);
              if (error && error.response && error.response.status) {
                if (error.response.status === 400) {
                  let emailcheck = "";
                  let passwordcheck = "";
                  emailcheck = "" + error.response.data.email;
                  passwordcheck = "" + error.response.data.password1;
                  onError(emailcheck + ',' + passwordcheck);
                } else if ( error.response.status === 444 ) {
                  onError(error.response.data.message);
                } else {
                  onError(t('PROCESS_FAIL'));
                }
              } else {
                onError(t('PROCESS_FAIL'));
              }

              setIsLoading(false);
            });

          } else {
            onError(t('PROCESS_FAIL'));
          }
        } else {
          onError(t('PROCESS_FAIL'));
        }
        
      });
    }
  }

  async function handleReSubmit(event) {
    event.preventDefault();

    //1. email check
    axios.post(`${config.DJANGO_URL}/accounts/checklogin/`, {
      email: fields.email
    }).then( function(response) {
      //console.log(response.data);
      onError("미인증 회원이 아닙니다. 이메일을 다시 확인해 주세요");
    //1. email check error
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 445 ) {
          onError(error.response.data.message);
        }
        else if ( error.response.status === 446 ) {
          //2. 미인증 회원인 경우에 인증메일 다시발송로직 추가
          setIsLoading(true);
          axios.post(`${config.DJANGO_URL}/accounts/againconfirm/`, {
            email: fields.email
          }).then( function(response) {
            //3. 회원등록
            axios.post(`${config.DJANGO_URL}/dj-rest-auth/registration/`, {
              email: fields.email,
              password1: fields.password,
              password2: fields.confirmPassword
            }).then( function(response) {
              //console.log(response.data);
              setMessage(response.data.detail);
              setNewUser("test");
              setIsLoading(false);
            }).catch(function(error) {
              //console.log(error.response.data);
              if (error && error.response && error.response.status) {
                if (error.response.status === 400) {
                  let emailcheck = "";
                  let passwordcheck = "";
                  emailcheck = "" + error.response.data.email;
                  passwordcheck = "" + error.response.data.password1;
                  onError(emailcheck + ',' + passwordcheck);
                } else if ( error.response.status === 444 ) {
                  onError(error.response.data.message);
                } else {
                  onError(t('PROCESS_FAIL'));
                }
              } else {
                onError(t('PROCESS_FAIL'));
              }

              setIsLoading(false);
            });
          }).catch(function (error) {
            onError(t('PROCESS_FAIL'));
            setIsLoading(false);
          });
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
    });
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
    axios.post(`${config.DJANGO_URL}/accounts/login/`, {
                email: fields.email,
                password: fields.password
    }).then(function(response) {

        axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('access_user', JSON.stringify(response.data.user));
        setUser(response.data.user);
        userHasAuthenticated(true);
        history.push("/");
        
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
        
        setMessage(t('LOGIN_FAIL'));
        userHasAuthenticated(false);
        setIsLoading(false);
    });
    
  }

  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`,{
      params: {route_id: route_id, is_publish: '1'}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`,{
          params: {id: route_id, section: section_id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`,{
            params: {route_id: route_id, section_id: section_id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);

          history.push("/");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
          console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/',{
      params: {route_id: route_id}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/',{
          params: {id: route_id, section: response.data.routesection.id}
        }).then(function(response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
          history.push("/");
        }).catch(function (error) {
          console.log(error);
        });               
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    }); 
  };

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }

  const appleResponse = response => {
    if (!response.error) {
      //console.log(response.authorization.code);
      //console.log(response.authorization.id_token);
      axios.get(`${config.DJANGO_URL}/accounts/apple/callback/react/signup/`,
            {
              params:{
                  code:response.authorization.code,
              },
            }
          ).then((response) => {
            //console.log(response.data);
               
            //get token
            let user = null;
            var route_id = "";
            var section_id = "";
            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            localStorage.setItem('access_user', JSON.stringify(response.data.user));
            setUser(response.data.user);
            userHasAuthenticated(true);
            setToken('robotaxi',response.data.user.email);
            user = response.data.user;
            
            //console.log(routeAppContext);
            axiosInstance.get('/robotaxi/route/').then(function(response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              if (routeAppContext) {
                initialPostSetting(routeAppContext.id);
              } else {
                let idx = 0;
                let apply_route = null;
                response.data.map( (data_row) => {
                  if (data_row.is_publish && data_row.id == user.route_default) {
                    apply_route = data_row;
                    setRouteAppContext(data_row);
                    idx++;
                  }
                });
        
                if ( idx == 0 ) {
                  apply_route = response.data[0];
                  setRouteAppContext(response.data[0]);
                }

                route_id = apply_route.id;
                initialPostSetting(route_id);
              }
            }).catch(function (error) {
              console.log(error.response.data);
            });
        
        }).catch((error) => {
            console.log(error);
        });
    }
  };

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>{message}</Form.Label>
          <Form.Text muted>발송된 메일에서 인증해 주세요.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="dark"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          로그인
        </LoaderButton>
      </Form>
    );
  }
  
  function renderForm() {
    return (
    <Stack gap={3}>
      <div className="bg-light border">
      <div className="bg-light border">
        <Nav sticky="top">
          <LinkContainer to="/signup">
            <Nav.Link>{t('SIGNUP')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/login">
            <Nav.Link>{t('LOGIN')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/home">
            <Nav.Link>{t('HOME')}</Nav.Link>
          </LinkContainer>
        </Nav>
      </div>
      </div>
      {/*
      <div className="bg-light border">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="authcode" size="lg">
            <Form.Label>{t('AUTHCODE')}</Form.Label>
            <Form.Control
              type="text"
              onChange={handleFieldChange}
              value={fields.authcode}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={isConfirm}
          >
            {t('AUTHVERIFY')}
          </LoaderButton>
        </Form>
      </div>
      */}
      <div className="bg-light border">
      {isConfirm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>{t('EMAIL')}</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t('PASSWORD')}</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>{t('CONFIRMPASSWORD')}</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          {t('PASSWORDINFO')}
          </Form.Group>
          <LoaderButton
            block
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            {t('SIGNUP')}
          </LoaderButton>
          <LoaderButton
            block
            variant="dark"
            onClick={handleReSubmit}
            disabled={!validateForm()}
            isLoading={isLoading}
          >
            인증메일 재발송
          </LoaderButton>  
        </Form>
      ) : (
			  <>
			  <h5>{message}</h5>
			  </>
			)}
      </div>
      <hr />
      <div className="SocialLogin">
        <FlexContainer>
          <GridContainer>
            {isSwitchOnService &&
              isSwitchOnPrivate &&
              isSwitchOnAgeLimit ? 
            <>
              <KakaoShareButton href={KAKAO_AUTH_URL_SIGNUP}>
                <KakaoIcon src={kakaoLogo}></KakaoIcon>
              </KakaoShareButton>
              {/*
              <KakaoShareButton href={GOOGLE_AUTH_URL_SIGNUP}>
                <KakaoIcon src={googleLogo}></KakaoIcon>
              </KakaoShareButton>
              */}
              <AppleLogin
                clientId="ai.sonnet.raxi.web"
                redirectURI={config.REDIRECT_URI_APPLE}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="form_post"
                render={renderProps => (  //Custom Apple Sign in Button
                <KakaoIcon src={appleLogo} onClick={renderProps.onClick}></KakaoIcon>
                            )}
              />
            </>
            :
            <>
              <KakaoShareButton>
                <KakaoIcon src={kakaoLogo}></KakaoIcon>
              </KakaoShareButton>
              {/*
              <KakaoShareButton>
                <KakaoIcon src={googleLogo}></KakaoIcon>
              </KakaoShareButton>
              */}
              <AppleLogin
                clientId="ai.sonnet.raxi.web"
                redirectURI={config.REDIRECT_URI_APPLE}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="form_post"
                render={renderProps => (  //Custom Apple Sign in Button
                <KakaoIcon src={appleLogo}></KakaoIcon>
                            )}
              />
            </>
            }
            
          </GridContainer>
        </FlexContainer>
      </div>
      <div className="bg-light border">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm>
              <ToggleSwitch label={t('TERMSOFAGE')} isChecked={isSwitchOnAgeLimit} onChange={onSwitchActionAgeLimit}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFSERVICE')} isChecked={isSwitchOnService} onChange={onSwitchActionService}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFPRIVACY')} isChecked={isSwitchOnPrivate} onChange={onSwitchActionPrivate}/>
            </Col>
          </Row>
        </Container>
        <Accordion alwaysOpen="False">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t('TERMSOFSERVICE')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfService />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t('TERMSOFPRIVACY')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfPrivacy />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
    );
   }
   
   return (
    <>
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </>
   );
}