import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';
import TableTimePicker from "./TableTimePicker";
import ToggleSwitch from "../components/ToggleSwitch";
import * as dateFns from "date-fns";

function RouteLIneInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [route, setRoute] = useState(null);
  const [routename, setRoutename] = useState("");
  const [ref_lat, setRef_lat] = useState("");
  const [ref_lng, setRef_lng] = useState("");
  const [defalt_zoom, setDefalt_zoom] = useState("");
  const [routegubun, setRoutegubun] = useState("");
  const [charge, setCharge] = useState("");
  const [persons, setPersons] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [content, setContent] = useState("");
  const [is_publish, setIs_publish] = useState(false);
  const [is_pay, setIs_pay] = useState(false);
  const [is_round, setIs_round] = useState(false);
  const [cmsid, setCmsid] = useState("");

  const [is_goworkhome, setIs_goworkhome] = useState(false);
  const [goworkfrom, setGoworkfrom] = useState(new Date());
  const [goworkto, setGoworkto] = useState(new Date());
  const [gohomefrom, setGohomefrom] = useState(new Date());
  const [gohometo, setGohometo] = useState(new Date());
  const [sparetime, setSparetime] = useState("");
  const [imgsrc, setImgsrc] = useState("");

  const onSwitchActionPublish = () => {
    setIs_publish(!is_publish);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionPay = () => {
    setIs_pay(!is_pay);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionRound = () => {
    setIs_round(!is_round);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionGoWorkHome = () => {
    setIs_goworkhome(!is_goworkhome);
    //console.log(isSwitchOnPrivate);
  };

  function onChangeGoworkfrom(event) {
    //console.log(dateFns.format(event,"yyyy-MM-dd'T'HH:mm:00"));
    setGoworkfrom(event);
  }

  function onChangeGoworkto(event) {
    setGoworkto(event);
  }

  function onChangeGohomefrom(event) {
    setGohomefrom(event);
  }

  function onChangeGohometo(event) {
    setGohometo(event);
  }
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get(`/robotaxi/route/${id}/`).then(function(response) {
          //console.log(response.data);
          const route = response.data;
          const { content } = route;
          setContent(content);
          setRoute(route);
          setRoutename(route.routename);
          setIs_publish(route.is_publish);
          setIs_pay(route.is_pay);
          setIs_round(route.is_round);
          setRef_lat(route.ref_lat);
          setRef_lng(route.ref_lng);
          setDefalt_zoom(route.defalt_zoom);
          setCmsid(route.cmsid);
          setRoutegubun(route.routegubun);
          setCharge(route.charge);
          setPersons(route.persons);
          setIs_goworkhome(route.is_goworkhome);
          setGoworkfrom(new Date(route.goworkfrom));
          setGoworkto(new Date(route.goworkto));
          setGohomefrom(new Date(route.gohomefrom));
          setGohometo(new Date(route.gohometo));
          setSparetime(route.sparetime);
          setImgsrc(route.imgsrc);
        }).catch(function (error) {
          //console.log(error);
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      routename.length > 0 &&
      ref_lat.length > 0 &&
      ref_lng.length > 0 &&
      content.length > 0
    );
  }
  
  function saveRoute(route) {
    axiosInstance.put(`/robotaxi/route/${id}/`, route).then(function(response) {
      //console.log(response.data);
      return;
    }).catch(function (error) {
      //console.log(error);
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      saveRoute({
        routename: routename,
        content: content,
        is_publish: is_publish,
        is_pay: is_pay,
        is_round: is_round,
        ref_lat: ref_lat,
        ref_lng: ref_lng,
        defalt_zoom: defalt_zoom,
        cmsid: cmsid,
        routegubun: routegubun,
        charge: charge,
        persons: persons,
        is_goworkhome: is_goworkhome,
        goworkfrom: dateFns.format(goworkfrom,"yyyy-MM-dd'T'HH:mm:00"),
        goworkto: dateFns.format(goworkto,"yyyy-MM-dd'T'HH:mm:00"),
        gohomefrom: dateFns.format(gohomefrom,"yyyy-MM-dd'T'HH:mm:00"),
        gohometo: dateFns.format(gohometo,"yyyy-MM-dd'T'HH:mm:00"),
        sparetime: sparetime,
        imgsrc: imgsrc
      });
    
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteRoute() {
    return axiosInstance.delete(`/robotaxi/route/${id}/`, route).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          onError(error);
          setIsLoading(false);
        }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteRoute();
      
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onEditorChange( evt ) {
    setContent( evt.editor.getData() );
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {route && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="routename">
                <Form.Label>노선명</Form.Label>
                <Form.Control
                  type="text"
                  value={routename}
                  onChange={(e) => setRoutename(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="ref_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={ref_lat}
                  onChange={(e) => setRef_lat(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="ref_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={ref_lng}
                  onChange={(e) => setRef_lng(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="defalt_zoom">
                <Form.Label>지도Zoom값</Form.Label>
                <Form.Control
                  type="text"
                  value={defalt_zoom}
                  onChange={(e) => setDefalt_zoom(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="cmsid">
                <Form.Label>CMS Route ID</Form.Label>
                <Form.Control
                  type="text"
                  value={cmsid}
                  onChange={(e) => setCmsid(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="routegubun">
                <Form.Label>노선운영구분(예약호출택시 : A, 고정노선버스 : B )</Form.Label>
                <Form.Control
                  type="text"
                  value={routegubun}
                  onChange={(e) => setRoutegubun(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>운임(운영구분A: 건당, 운영구분B: 인당)</Form.Label>
                <Form.Control
                  type="text"
                  value={charge}
                  onChange={(e) => setCharge(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="persons">
                <Form.Label>탑승인원</Form.Label>
                <Form.Control
                  type="text"
                  value={persons}
                  onChange={(e) => setPersons(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sparetime">
                <Form.Label>여유시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={sparetime}
                  onChange={(e) => setSparetime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="imgsrc">
                <Form.Label>구간이미지</Form.Label>
                <Form.Control
                  type="text"
                  value={imgsrc}
                  onChange={(e) => setImgsrc(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="goworkhome">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 출퇴근설정</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="출퇴근적용" isChecked={is_goworkhome} onChange={onSwitchActionGoWorkHome}/>
                    </Col>
                  </Row>
                  { is_goworkhome && 
                    <Container>
                      <Row>
                        <Form.Label>출근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkfrom}
                            onChange={onChangeGoworkfrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkto}
                            onChange={onChangeGoworkto}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label>퇴근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohomefrom}
                            onChange={onChangeGohomefrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohometo}
                            onChange={onChangeGohometo}
                          />
                        </Col>
                      </Row>
                    </Container>
                  }
                </Container>
              </Form.Group>
              <Form.Group controlId="subject">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="운행" isChecked={is_publish} onChange={onSwitchActionPublish}/>
                    </Col>
                    <Col>
                      <ToggleSwitch label="유료" isChecked={is_pay} onChange={onSwitchActionPay}/>
                    </Col>
                    <Col>
                      <ToggleSwitch label="예약" isChecked={is_round} onChange={onSwitchActionRound}/>
                    </Col>
                  </Row>
                </Container>
              </Form.Group>
              <Form.Group controlId="content">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
                <CKEditor 
                  initData={content} 
                  onChange={onEditorChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteLIneInfo;