import React, { useRef, useState, useEffect } from "react";
import "./Destination.css";
import { Form, Row, Col, ListGroup, Offcanvas, Button, Stack, Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import TableDateTimePicker from "./TableDateTimePicker";
import { useAppContext } from "../libs/contextLib";
import { FaHandPointRight } from "react-icons/fa";
import axiosInstance from "../axiosApi";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import ChooseRouteSection from "./ChooseRouteSection";
import config from "../config";
import * as dateFns from "date-fns";
import OffCarSequenceList from "./OffCarSequenceList";

function BillCompleteModal(props) {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('BILL_TITLE')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{t('BILL_STATUS')}</h4>
                <p>
                    {t('BILL_RESULT_OK')}<br />
                    {t('BILL_RESULT_ETC')}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function BookingResult(props) {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <h5>출발/도착지 빈좌석 내역</h5>
                {props.bookPersons.map((data) => (
                    <ListGroup key={data.booktime}>
                        <ListGroup.Item>정류장: {data.station.stationname}</ListGroup.Item>
                        <ListGroup.Item>시간: {dateFns.format(new Date(data.booktime), 'HH:mm')}, 빈좌석: {data.persons}</ListGroup.Item>
                    </ListGroup>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ModalChooseRouteSection(props) {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    노선변경화면
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ChooseRouteSection />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function OffDestination() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [carOptions, setCarOptions] = useState([]);
    const [personsOptions, setPersonsOptions] = useState([]);
    const [routeSectionOptions, setRouteSectionOptions] = useState([]);
    const [persons, setPersons] = useState(0);

    const { setRouteAppContext } = useAppContext();
    const { routeAppContext } = useAppContext();

    const { ticketGubunAppContext } = useAppContext();
    const { setTicketGubunAppContext } = useAppContext();

    const { strRoundDateAppContext } = useAppContext();
    const { setStrRoundDateAppContext } = useAppContext();

    const { endRoundDateAppContext } = useAppContext();
    const { setEndRoundDateAppContext } = useAppContext();

    const { strStationAppContext } = useAppContext();
    const { setStrStationAppContext } = useAppContext();

    const { endStationAppContext } = useAppContext();
    const { setEndStationAppContext } = useAppContext();

    const { endSingleDateAppContext } = useAppContext();
    const { setEndSingleDateAppContext } = useAppContext();

    const { chargeAppContext } = useAppContext();
    const { setChargeAppContext } = useAppContext();

    const { leadtimeAppContext, setLeadtimeAppContext } = useAppContext();
    const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
    const { routeMap, setRouteMap } = useAppContext();
    const [runDistance, setRunDistance] = useState("0");

    const { setCarAppContext } = useAppContext();
    const { carAppContext } = useAppContext();

    const { setCarRoundAppContext } = useAppContext();
    const { carRoundAppContext } = useAppContext();

    const { setStrSingleDateAppContext } = useAppContext();
    const { strSingleDateAppContext } = useAppContext();

    const [station, setStation] = useState([]);
    const [strStationOptions, setStrStationOptions] = useState([]);
    const [endStationOptions, setEndStationOptions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalResultShow, setModalResultShow] = useState(false);
    const [modalShowRouteSection, setModalShowRouteSection] = useState(false);
    const [bookPersons, setBookPersons] = useState([]);
    const [bookDate, setBookDate] = useState('');
    const [updateState, setUpdateState] = useState(new Date());

    const { t } = useTranslation();

    function showModal() {
        setModalShow(true);
    }

    function hideModal() {
        setPersonsOptions([]);
        setPersons(0);
        setModalShow(false);
    }

    function showResultModal() {
        setModalResultShow(true);
    }

    function hideResultModal() {
        setModalResultShow(false);

        setTicketGubunAppContext("S");
        setStrRoundDateAppContext("");
        setEndRoundDateAppContext("");
        setStrSingleDateAppContext("");
        setEndSingleDateAppContext("");

        setUpdateState(new Date());
    }

    function showModalRouteSection() {
        setModalShowRouteSection(true);
    }

    function hideModalRouteSection() {
        setModalShowRouteSection(false);
    }

    const onChangeHandlerSingle = (arg_ticket, event) => {
        setTicketGubunAppContext('S');
        setStrRoundDateAppContext('');
        setCarRoundAppContext('');
    }

    useEffect(() => {
        async function onLoad() {
            await axiosInstance.get('/robotaxi/robocar_run/', {
                params: { route_id: routeAppContext.id, section_id: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                const cars = response.data;
                setCarAppContext(response.data[0]);
                setCarOptions(cars.map(data => ({ name: data.carname, id: data.id, car: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            axiosInstance.get('/robotaxi/basicinfo/').then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setBookDate(response.data.current_datetime);
            }).catch(function (error) {
                setIsLoading(false);
            });

            await axiosInstance.get('/robotaxi/routesection/', {
                params: { route_id: routeAppContext.id, is_publish: '1' }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setRouteSectionOptions(response.data.map(data => ({ name: data.sectionname, id: data.id, section: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            axiosInstance.get('/robotaxi/routemap/', {
                params: { id: routeAppContext.id, section: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //console.log(response.data);
                var s_id = "";
                var e_id = "";
                setStation(response.data);
                setStrStationOptions([]);
                setEndStationOptions([]);

                if (routeSectionAppContext.routegubun == "B") {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence < endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence > strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                } else {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                }
            }).catch(function (error) {
                setIsLoading(false);
            });

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(leadtimeAppContext);
        // 1. 출발지 정류장 설정
        setStrStationOptions([]);
        if (endStationAppContext && routeSectionAppContext.routegubun == "B") {
            // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
            station.map((data) => {
                if (data.sequence < endStationAppContext.sequence) {
                    setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        } else {
            station.map((data) => {
                if (data.sequence !== endStationAppContext.sequence) {
                    setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        }
        // 2. 도착지 정류장 설정
        setEndStationOptions([]);
        if (strStationAppContext && routeSectionAppContext.routegubun == "B") {
            // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
            station.map((data) => {
                //console.log(data.sequence);
                if (data.sequence > strStationAppContext.sequence) {
                    setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        } else {
            station.map((data) => {
                //console.log(data.sequence);
                if (data.sequence !== strStationAppContext.sequence) {
                    setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        }

        if (!strSingleDateAppContext) {
            return;
        }
        getChargeAndTime();
        //console.log(dateFns.format(dateFns.add(strSingleDateAppContext,{minutes: Number(leadtimeAppContext),}), "yyyy-MM-dd'T'HH:mm:ss"));
        if (routeSectionAppContext.routegubun == "B") {
            axiosInstance.get('/robotaxi/robotimetable/getpersons/', {
                params: {
                    s_date: dateFns.format(strSingleDateAppContext, "yyyy-MM-dd'T'HH:mm:ss"),
                    e_date: dateFns.format(dateFns.add(strSingleDateAppContext, { minutes: Number(leadtimeAppContext), }), "yyyy-MM-dd'T'HH:mm:ss"),
                    car_id: carAppContext.id,
                    route_id: routeAppContext.id,
                    routesection_id: routeSectionAppContext.id
                }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //console.log(response.data.persons);
                //1. check persons == 0 : 탑승 가능 인원이 없는 정류장
                var min_booktime = "";
                var chk_person = 100;
                response.data.persons.map((data) => {
                    if (Number(data.persons) == 0) {
                        setBookPersons(response.data.persons);
                        showModal();
                        return;
                    }

                    if (Number(data.persons) < chk_person) {
                        chk_person = Number(data.persons);
                        min_booktime = data.booktime;
                    }
                });

                //2. booktime == s_time : 탑승인원 표시
                response.data.persons.map((data) => {
                    //console.log(dateFns.format(new Date(data.booktime), 'yyyy-MM-dd HH:mm:ss'));
                    if (dateFns.format(new Date(data.booktime), 'yyyy-MM-dd HH:mm:ss') == dateFns.format(new Date(min_booktime), 'yyyy-MM-dd HH:mm:ss')) {
                        let i = 1;
                        var arr_persons = [];
                        for (; i <= Number(data.persons); i++) {
                            arr_persons.push({ id: i, name: i + " 명" });
                            //setPersonsOptions(personsOptions => [...personsOptions, { id: i, name: i + " 명" }]);
                        }
                        setPersonsOptions(arr_persons);
                        setPersons(1);
                    }
                });
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            let i = 1;
            var arr_persons = [];
            for (; i <= Number(carAppContext.persons); i++) {
                arr_persons.push({ id: i, name: i + " 명" });
            }
            setPersonsOptions(arr_persons);
            setPersons(1);
        }
    }, [carAppContext, leadtimeAppContext, strSingleDateAppContext, strStationAppContext, endStationAppContext]);

    function validateForm() {
        return String(strSingleDateAppContext).length > 0 &&
            (Object.keys(strStationAppContext).length != 0) &&
            (Object.keys(endStationAppContext).length != 0) &&
            persons > 0;
    }

    async function getChargeAndTime() {
        try {
            const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                params: {
                    id: routeAppContext.id,
                    section: routeSectionAppContext.id,
                    s_book: strStationAppContext.station.id,
                    e_book: endStationAppContext.station.id
                }
            });
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            let strtime, endtime, rstrtime, rendtime;
            strtime = new Date(strSingleDateAppContext);
            endtime = moment(strtime).add(Number(response.data.sum_leadtime), 'minutes')
            setEndSingleDateAppContext(endtime);

            rstrtime = new Date(strRoundDateAppContext);
            rendtime = moment(rstrtime).add(Number(response.data.sum_leadtime), 'minutes')
            setEndRoundDateAppContext(rendtime);

            if (routeSectionAppContext.is_pay) {
                if (routeSectionAppContext.routegubun == 'B') {
                    setChargeAppContext(String(routeSectionAppContext.charge * persons));
                } else {
                    setChargeAppContext(String(routeSectionAppContext.charge));
                }
            } else {
                setChargeAppContext(String(0));
            }
            setLeadtimeAppContext(response.data.sum_leadtime);
            setRunDistance(response.data.sum_rundistance);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function saveBooking(data) {
        try {
            const response = await axiosInstance.post(`/robotaxi/bookingcar/`, data['S']);
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            showResultModal();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        };
    }

    async function handleSubmit(event) {
        event.preventDefault();
        var dictBook = {};
        var car_direction = 'A';
        var car_direction_round = 'B';
        setIsLoading(true);

        if (Number(strStationAppContext.sequence) < Number(endStationAppContext.sequence)) {
            car_direction = 'A';
            car_direction_round = 'B';
        } else {
            car_direction = 'B';
            car_direction_round = 'A';
        }

        dictBook['S'] = {
            "ing_status": "E",
            "routename": routeAppContext.id,
            "section": routeSectionAppContext.id,
            "book_date": bookDate,
            "s_station": strStationAppContext.station.id,
            "s_date": moment(strSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "e_station": endStationAppContext.station.id,
            "e_date": moment(endSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "leadtime": leadtimeAppContext,
            "rundistance": runDistance,
            "charge": chargeAppContext,
            "est_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "car_direction": car_direction,
            "rs_date": moment(strSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "re_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "ticket": 'X',
            "s_sequence": strStationAppContext.sequence,
            "e_sequence": endStationAppContext.sequence,
            "car": carAppContext.id,
            "persons": persons
        };

        try {
            //console.log(dictBook);
            await saveBooking(dictBook);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function onChangeCar(event) {
        const result = carOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result) {
            setCarAppContext(result.car);
        } else {
            setCarAppContext("");
            setStrSingleDateAppContext("");
        }
    }

    function onChangeRouteSection(event) {
        var s_id = "";
        var e_id = "";
        const result = routeSectionOptions.find(option => Number(option.id) === Number(event.target.value));
        console.log(result);

        if (result) {
            setRouteSectionAppContext(result.section);

            axiosInstance.get('/robotaxi/routemap_list_allowany/',{
                params: {id: routeAppContext.id, section: result.section.id}
            }).then(function(response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            //console.log(response.data);
            setRouteMap(response.data);
            setStrSingleDateAppContext('');
            setStrStationAppContext(response.data[0]);
            s_id = response.data[0].station.id;
            setEndStationAppContext(response.data[response.data.length - 1]);
            e_id = response.data[response.data.length - 1].station.id;
            getChargeAndTime(s_id, e_id, routeAppContext.id);
            axiosInstance.get('/robotaxi/robocar_run/',{
                params: {route_id: routeAppContext.id, section_id: result.section.id}
            }).then(function(response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setCarAppContext(response.data[0]);
            }).catch(function (error) {
                console.log(error);
            });
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    function onChangePersons(event) {
        setPersons(Number(event.target.value));
    }

    function onChangeStrSingleDate(event) {
        // chk_realtime = 'Y' : 실시간 예약으로 적용
        let minutes = 0;

        minutes = dateFns.differenceInMinutes(new Date(), new Date(event))
        // dateFns.format(event,'YYYY-MM-DDTHH:mm:ss')
        let select_date = dateFns.format(event, "HH");
        if (select_date === '00') {
            let minDate = new Date(event);
            minDate.setHours(10);
            setStrSingleDateAppContext(minDate);
        } else {
            setStrSingleDateAppContext(event);
        }
        setStrRoundDateAppContext('');
    }

    async function onChangeStrStation(event) {
        var s_id = "";
        const result = strStationOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result && result.station.sequence !== endStationAppContext.sequence) {
            setStrStationAppContext(result.station);
            s_id = result.station.station.id;

            try {
                const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                    params: {
                        id: routeAppContext.id,
                        section: routeSectionAppContext.id,
                        s_book: s_id,
                        e_book: endStationAppContext.station.id
                    }
                });
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setLeadtimeAppContext(response.data.sum_leadtime);
            } catch (error) {
                setIsLoading(false);
            }
        } else {
            setStrStationAppContext("");
            s_id = "";
        }
        setStrSingleDateAppContext('');
    }

    async function onChangeEndStation(event) {
        var e_id = "";
        const result = endStationOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result && result.station.sequence !== strStationAppContext.sequence) {
            setEndStationAppContext(result.station);
            e_id = result.station.station.id;

            /**
            var find_rtn = endStationOptions.find(option => option.station.sequence > result.station.sequence);
            if (find_rtn) {
              setStrRoundStationAppContext(find_rtn.station);
            } else {
              setStrRoundStationAppContext(station[0]);
            }
             */

            try {
                const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                    params: {
                        id: routeAppContext.id,
                        section: routeSectionAppContext.id,
                        s_book: strStationAppContext.station.id,
                        e_book: e_id
                    }
                });
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setLeadtimeAppContext(response.data.sum_leadtime);
            } catch (error) {
                setIsLoading(false);
            }
        } else {
            setEndStationAppContext("");
        }
    }

    const handleClick = () => {
        showModalRouteSection();
    }

    return (
        <Stack gap={3}>
            <div className="bg-light"></div>
            <div className="bg-light border">
                <Form className="form2" onSubmit={handleSubmit}>
                    <Row className="mx-0 my-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                            <Col className="my-0" xs={2} md={2}>
                                    <Form.Label className="formLabel">노선</Form.Label>
                                </Col>
                                <Col className="my-0" xs={10} md={10}>
                                    <div className="form">
                                        <label></label>{"   "}
                                        <select value={routeSectionAppContext === '' ? '' : routeSectionAppContext.id} disabled>
                                            {
                                                routeSectionOptions.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>                                  
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} >       
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={2} md={2}>
                                    <Form.Label className="formLabel">{t('CAR')}</Form.Label>
                                </Col>
                                <Col className="my-0" xs={10} md={10}>
                                    <DropdownSelector
                                        labelName=""
                                        options={carOptions}
                                        defaultValue={carAppContext ? carAppContext.id : ""}
                                        onChange={onChangeCar}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={2} md={2}>
                                    <Form.Label className="formLabel">{t('DEPART')}</Form.Label>
                                </Col>
                                <Col className="my-0" xs={10} md={10}>
                                    <DropdownSelector
                                        labelName=""
                                        options={strStationOptions}
                                        defaultValue={strStationAppContext === '' ? '' : strStationAppContext.station.id}
                                        onChange={onChangeStrStation}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={2} md={2}>
                                    <Form.Label className="formLabel">{t('ARRIVAL')}</Form.Label>
                                </Col>
                                <Col className="my-0" xs={10} md={10}>
                                    <DropdownSelector
                                        labelName=""
                                        options={endStationOptions}
                                        defaultValue={endStationAppContext === '' ? '' : endStationAppContext.station.id}
                                        onChange={onChangeEndStation}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={2} md={2}>
                                    <Form.Label className="formLabel">{t('DEPARTTIME')}</Form.Label>
                                </Col>
                                <Col className="my-0" xs={10} md={10}>
                                    {(carAppContext.length != 0) && (
                                        <TableDateTimePicker
                                            wrapperClassName="datepicker"
                                            selected={strSingleDateAppContext}
                                            onChange={onChangeStrSingleDate}
                                            car={carAppContext}
                                            chk_realtime='Y'
                                        />
                                    )}
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-0">
                        <Col>
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={4} md={2}>
                                    <Form.Label className="formLabel">{t('PERSONS')}</Form.Label>
                                </Col>
                                <Col className="my-0" xs={8} md={10}>
                                    <div className="form">
                                        <select name="" onChange={onChangePersons} value={persons}>
                                            {
                                                personsOptions.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleClick}>노선변경
                                    </Button>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-1">
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            예약
                        </LoaderButton>
                    </Row>
                </Form>
            </div>
            <div className="bg-light border">
                <OffCarSequenceList updateState={updateState} />
            </div>
            <div>
                <BookingResult
                    show={modalShow}
                    onHide={hideModal}
                    bookPersons={bookPersons}
                />
            </div>
            <div>
                <BillCompleteModal
                    show={modalResultShow}
                    onHide={hideResultModal}
                />
            </div>
            <div>
                <ModalChooseRouteSection
                    show={modalShowRouteSection}
                    onHide={hideModalRouteSection}
                />
            </div>
        </Stack>
    );
}