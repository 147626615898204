import React from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { register } from '../serviceWorkerRegistration';
import { useTranslation } from 'react-i18next';

const ServiceWorkerWrapper = () => {
  const { t } = useTranslation();
  const [showReload, setShowReload] = React.useState(false);
  const [installingWorker, setInstallingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setInstallingWorker(registration.installing);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    //installingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={t('MSG_RELOAD')}
      onClick={reloadPage}
      data-test-id="screens-new-version-snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          {t('RELOAD')}
        </Button>
      }
    />
  );
}

export default ServiceWorkerWrapper;