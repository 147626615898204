import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useAppContext } from "../libs/contextLib";
import GoogleMapAPI from "../components/GoogleMapAPI";

function BookingCar() {
  const [bookingCar, setBookingCar] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [stateOptions, setStateOptions] = useState([
    { name: t('INGSTATUS_001'), id: "A" },
    { name: t('INGSTATUS_002'), id: "B" }, ,
    { name: t('INGSTATUS_003'), id: "C" },
    { name: t('INGSTATUS_004'), id: "D" },
    { name: t('INGSTATUS_005'), id: "E" },
    { name: t('INGSTATUS_006'), id: "F" },
    { name: t('INGSTATUS_007'), id: "G" },
    { name: t('INGSTATUS_008'), id: "H" },
  ]);
  const { routeAppContext } = useAppContext();
  const { routeMap, setRouteMap } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setStrRoundDateAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setEndSingleDateAppContext } = useAppContext();
  const { setChargeAppContext } = useAppContext();
  const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));

  const columns = [
    {
      dataField: "section",
      text: t('LINE')
    },
    {
      dataField: "s_date",
      text: t('COL_TIME')
    },
    {
      dataField: "s_station",
      text: t('COL_DEPART')
    },
    {
      dataField: "e_station",
      text: t('COL_ARRIVAL')
    },
    {
      dataField: "e_date",
      text: t('COL_ARRIVAL_TIME')
    },
    {
      dataField: "ing_status",
      text: t('COL_STATUS')
    }
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <IconContext.Provider
        value={{ style: { color: '#A4BEF3', fontSize: '30px' } }}
      >
        <div>
          <FaCloudDownloadAlt onClick={handleClick} />
          {" "}To CSV
        </div>
      </IconContext.Provider>
      //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  const products = [
    { id: 1, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "배차진행" },
    { id: 2, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "차량이동" },
    { id: 3, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "승차대기" }
  ];

  const [isSwitchOnCheckCancel, setIsSwitchOnCheckCancel] = useState(true);

  const onSwitchActionCheckCancel = () => {
    if (!isSwitchOnCheckCancel) {
        axiosInstance.get('/robotaxi/bookingcar/', {
          params: { ing_status: 'F', route_id: routeAppContext.id, }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setBookingCar([]);
          response.data.map((data_row) => {
            let book = {};

            book['id'] = data_row.id
            book['section'] = data_row.routesection.sectionname
            book['s_station'] = data_row.s_station.stationname
            book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
            book['e_date'] = moment(new Date(data_row.e_date)).format("MM.DD(ddd) HH:mm")
            book['e_station'] = data_row.e_station.stationname
            book['ing_status'] = data_row.ing_status_name
            book['car'] = data_row.car.carname
            setBookingCar(bookingCar => [...bookingCar, book])
            //setBookingCar(bookingCar.concat(book))
          });
        }).catch(function (error) {
          setIsLoading(false);
        });
    } else {
        axiosInstance.get('/robotaxi/bookingcar/', {
          params: { ing_status: 'G', route_id: routeAppContext.id, }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setBookingCar([]);
          response.data.map((data_row) => {
            let book = {};

            book['id'] = data_row.id
            book['section'] = data_row.routesection.sectionname
            book['s_station'] = data_row.s_station.stationname
            book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
            book['e_date'] = moment(new Date(data_row.e_date)).format("MM.DD(ddd) HH:mm")
            book['e_station'] = data_row.e_station.stationname
            book['ing_status'] = data_row.ing_status_name
            book['car'] = data_row.car.carname
            setBookingCar(bookingCar => [...bookingCar, book])
            //setBookingCar(bookingCar.concat(book))
          });
        }).catch(function (error) {
          setIsLoading(false);
        });
    }
    setIsSwitchOnCheckCancel(!isSwitchOnCheckCancel);
  };

  useEffect(() => {
    async function onLoad() {
        axiosInstance.get('/robotaxi/route/').then(function (response) {
          if (routeAppContext) {
            axiosInstance.get('/robotaxi/routemap/', {
              params: { id: routeAppContext.id, section: routeSectionAppContext.id }
            }).then(function (response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              setRouteMap(response.data);
            }).catch(function (error) {
              console.log(error);
            });

          } else {
            let idx = 0;
            let apply_route = null;
            response.data.map((data_row) => {
              if (data_row.is_publish && data_row.id == tempUser.route_default) {
                apply_route = data_row;
                setRouteAppContext(data_row);
                idx++;
              }
            });

            if (idx == 0) {
              apply_route = response.data[0];
              setRouteAppContext(response.data[0]);
            }

            axiosInstance.get('/robotaxi/routemap/', {
              params: { id: apply_route.id, section: routeSectionAppContext.id }
            }).then(function (response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              setRouteMap(response.data);
            }).catch(function (error) {
              console.log(error);
            });

            setStrStationAppContext("");
            setStrSingleDateAppContext('');
            setStrRoundDateAppContext('');
            setEndStationAppContext('');
            setEndSingleDateAppContext('');
            setChargeAppContext('');
          }

        }).catch(function (error) {
          onError(error);
        });

        setBookingCar([]);
        axiosInstance.get('/robotaxi/bookingcar/', {
          params: { ing_status: 'X', route_id: routeAppContext.id, }
        }).then(function (response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          response.data.map((data_row) => {
            let book = {};

            book['id'] = data_row.id
            book['section'] = data_row.routesection.sectionname
            book['s_station'] = data_row.s_station.stationname
            book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
            book['e_date'] = moment(new Date(data_row.e_date)).format("MM.DD(ddd) HH:mm")
            book['e_station'] = data_row.e_station.stationname
            book['ing_status'] = data_row.ing_status_name
            book['car'] = data_row.car.carname
            setBookingCar(bookingCar => [...bookingCar, book])
            //setBookingCar(bookingCar.concat(book))
          });
        }).catch(function (error) {
          setIsLoading(false);
        });
    }

    onLoad();
  }, []);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log(`clicked on row with index: ${rowIndex}`);
      history.push(`/boarding/${row['id']}`);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
    bgColor: 'skyblue'
  };

  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
      console.log(`You just selected '${row['s_date']}'`);
    }
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-dark' : 'btn-secondary'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  return (
    <Stack gap={2}>
      <div className="bg-light border">
        <GoogleMapAPI />
      </div>
      <div className="bg-light border">
        {t('PROGRESS_INFO')}
        <ToolkitProvider
          keyField="s_date"
          data={bookingCar}
          columns={columns}
          exportCSV
        >
          {
            props => (
              <div>
                {/*
              <Container>
                <Row xs="auto">
                  <Col>
                    <ToggleSwitch label={t('EXCEPT_CANCEL')} isChecked={isSwitchOnCheckCancel} onChange={onSwitchActionCheckCancel}/>
                  </Col> 
                </Row>
              </Container>
              */}
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={bookingCar}

                  columns={columns}
                  striped
                  hover
                  condensed
                  bordered={false}
                  rowEvents={rowEvents}
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}

export default BookingCar;