import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem, NavDropdown, Button, Toast, Badge, ToastContainer, Spinner } from "react-bootstrap";
import { AppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import axiosInstance from "./axiosApi";
import LangDropdown from "./components/LangDropdown";
import { getToken, onMessageListener } from "./firebase";
import axios from 'axios'
import Logo from './images/raxi_logo.png';
import { useTranslation } from 'react-i18next';
import { BsFillAlarmFill } from "react-icons/bs";
import config from "./config";
import { differenceInMinutes, differenceInHours } from "date-fns";
import SubNavigation from "./components/SubNavigation";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";
import DropdownSelector from "./components/DropdownSelector";
import useWebSocket, { ReadyState } from 'react-use-websocket';

function App() {
    
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');

  const [routeAppContext, setRouteAppContext] = useState('');
  const [strStationAppContext, setStrStationAppContext] = useState('');
  const [endStationAppContext, setEndStationAppContext] = useState('');
  const [strRoundStationAppContext, setStrRoundStationAppContext] = useState('');
  const [endRoundStationAppContext, setEndRoundStationAppContext] = useState('');
  const [strSingleDateAppContext, setStrSingleDateAppContext] = useState('');
  const [endSingleDateAppContext, setEndSingleDateAppContext] = useState('');
  const [strRoundDateAppContext, setStrRoundDateAppContext] = useState('');
  const [endRoundDateAppContext, setEndRoundDateAppContext] = useState('');
  const [chargeAppContext, setChargeAppContext] = useState('');
  const [leadtimeAppContext, setLeadtimeAppContext] = useState('');
  const [ticketGubunAppContext, setTicketGubunAppContext] = useState("S");
  const [carAppContext, setCarAppContext] = useState('');
  const [carRoundAppContext, setCarRoundAppContext] = useState('');
  const [currentLocation, setCurrentLocation] = useState('/');
  const [routeMap, setRouteMap] = useState([]);
  const [routeSectionAppContext, setRouteSectionAppContext] = useState('');
  const [routeOptions, setRouteOptions] = useState([]);
  const [messageHistory, setMessageHistory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  
  const providerValue = useMemo(() => ({
        isAuthenticated, userHasAuthenticated,
        user, setUser,
        routeAppContext, setRouteAppContext,
        strStationAppContext, setStrStationAppContext,
        endStationAppContext, setEndStationAppContext,
        strRoundStationAppContext, setStrRoundStationAppContext,
        endRoundStationAppContext, setEndRoundStationAppContext,
        strSingleDateAppContext, setStrSingleDateAppContext,
        endSingleDateAppContext, setEndSingleDateAppContext,
        strRoundDateAppContext, setStrRoundDateAppContext,
        endRoundDateAppContext, setEndRoundDateAppContext,
        chargeAppContext, setChargeAppContext,
        leadtimeAppContext, setLeadtimeAppContext,
        ticketGubunAppContext, setTicketGubunAppContext,
        carAppContext, setCarAppContext,
        carRoundAppContext, setCarRoundAppContext,
        currentLocation, setCurrentLocation,
        routeMap, setRouteMap,
        messageHistory, setMessageHistory,
        routeSectionAppContext, setRouteSectionAppContext,
  }), [isAuthenticated, user, routeAppContext, strStationAppContext, endStationAppContext, strRoundStationAppContext, endRoundStationAppContext, strSingleDateAppContext, endSingleDateAppContext, strRoundDateAppContext, endRoundDateAppContext, chargeAppContext, leadtimeAppContext, ticketGubunAppContext, carAppContext, carRoundAppContext, currentLocation, routeMap, messageHistory, routeSectionAppContext]);
  
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  const [payload, setPayload] = useState(null);
  const [lapseMin, setLapseMin] = useState("");
  const [alarmCount, setAlarmCount] = useState(0);
  const [messageStore, setMessageStore] = useState([]);
  const [socketUrl, setSocketUrl] = useState(`${config.SOCKET_URL}/ws`);
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      if (JSON.parse(lastMessage.data).type == 'mapHD') {
        //setMessageHistory((prev) => prev.concat(lastMessage));
      } else {
        //console.log(lastMessage.data);
        //console.log(JSON.parse(lastMessage.data).data.route_id);
        //clearCarInfoHandler(JSON.parse(lastMessage.data).data);
        setMessageHistory(lastMessage.data);
      }

    }
  }, [lastMessage]);

  getToken(setTokenFound);

  onMessageListener().then(payload => {
      if (user !== null) {
        setShow(true);
    } else {
        setShow(false);
    }
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setPayload(payload);
    setLapseMin("just now");
    //console.log(payload);
  }).catch(err => console.log('failed: ', err));
  
  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => getMessageStore(), 10000);
    return () => clearTimeout(timeout);
  }, [messageStore]);

  function getMessageStore() {  
    try {
      if (isAuthenticated) {
        axiosInstance.get('/robotaxi/messagestorecheck/').then(function(response) {
          //console.log(response.data.length);
          if (typeof response !== 'undefined') {
            setMessageStore(response.data);
            if (isAuthenticated) {
              setAlarmCount(response.data.length)
            } else {
              setAlarmCount(0)
            }    
          }
        }).catch(function (error) {
          setAlarmCount(0)
          console.log(error);
        });
      }
    } catch (error) {
      //onError(error);
    }
  }

  async function onLoad() {
    try {
      axios.post(`${config.DJANGO_URL}/accounts/token/verify/`, {
        token: localStorage.getItem("access_token"),
      }).then(function(response) {
        //console.log(response);
        userHasAuthenticated(true);
        //console.log(JSON.parse(localStorage.getItem("access_user")));
        setUser(JSON.parse(localStorage.getItem("access_user")));
        getMessageStore();
      }).catch(function (error) {
        //console.log(error);
      });

      axios.get(`${config.DJANGO_URL}/robotaxi/route_list_allowany/`, {
        params: { is_publish: '0' }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteOptions(response.data.map(data => ({ name: data.routename, id: data.id, route: data })));
      }).catch(function (error) {
        //console.log(error);
      });

      setIsAuthenticating(false);
    } catch (error) {
      //onError(error);
    }
  }

  function handleLogout() {
    // add sign out login...
    //localStorage.removeItem('access_token');
    //localStorage.removeItem('refresh_token');
    //localStorage.removeItem('access_user');
    localStorage.clear();
    userHasAuthenticated(false);
    setUser(null);
    history.push('/intropage/' + routeAppContext.id);
  }
  
  const onChange = event => {
    //console.log(event);
    setSelectedValue(event.target.value );
  }
  
  const handleSelect = (eventKey) => {
    if (eventKey === 'en' || eventKey === 'kr') {
      //console.log('A key was pressed', eventKey);
      setSelectedLang(eventKey);
      i18n.changeLanguage(eventKey);
    }
  }
  
  const onClickToast = () => {
    //console.log("alarm count : " + alarmCount)
    var checklist = [];

    if (user !== null) {
      setShow(true);
    } else {
      setShow(false);
    }
    
    if (payload !== null) {
      setLapseMin(differenceInMinutes(new Date(), new Date(payload.data.crtdate)));
    }

    if (alarmCount > 0) {   
      messageStore.map( (data) => {
        checklist.push({"messagestore_id":data.id});
      });
      console.log(checklist);
      axiosInstance.post('/robotaxi/messagecheck/', {
        checklist,
      }).then(function(response) {
        //console.log(response.data);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`,{
      params: {route_id: route_id, is_publish: '1'}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`,{
          params: {id: route_id, section: section_id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`,{
            params: {route_id: route_id, section_id: section_id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
          console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/',{
      params: {route_id: route_id}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/',{
          params: {id: route_id, section: response.data.routesection.id}
        }).then(function(response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
        }).catch(function (error) {
          console.log(error);
        });               
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    }); 
  };

  async function onChangeRoute(event) {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result) {
      setIsLoading(true);
      setRouteAppContext(result.route);
      setRouteMap([]);

      if (user) {
        //가장최근 예약건을 가져와서 디폴트값 셋팅
        await initialPostSetting(result.route.id);
        setIsLoading(false);   
        history.push("/", {replace: true});
      } else {
        await initialPreSetting(result.route.id);
        setIsLoading(false);
      }        
    }
  }
  
  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
        const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/',{
            params: {
                id: route_id,
                section: section_id,
                s_book: s_id,
                e_book: e_id
            }
        });
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      console.log(error);
    }
  }

  return (
   !isAuthenticating && (
    <div className="App container-fluid py-1 mx-0">
      {notification.title != '' &&
        <Toast bg="dark" onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{notification.title}</strong>
            <small>{lapseMin}{lapseMin === 'just now' ? '' : '분전'}</small>
          </Toast.Header>
          <Toast.Body className="text-white">{notification.body}</Toast.Body>
        </Toast>
      }
      <ToastContainer>
      {messageStore && messageStore.map((data,index) => {
        return (
            <Toast key={data.id} bg="dark" onClose={() => setShow(false)} show={show} delay={5000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">{data.msg_title}</strong>
                <small>{differenceInHours(new Date(), new Date(data.crt_date))} 분전</small>
              </Toast.Header>
              <Toast.Body className="text-white">{data.msg_body}</Toast.Body>
            </Toast>
        );
      })}
      </ToastContainer>
      <Navbar fluid collapseOnSelect expand="md" className="navbar-dark bg-dark mx-0 px-0">
        <LinkContainer to={routeAppContext.id ? '/intropage/' + routeAppContext.id : '/'}>
          <Navbar.Brand className="mx-0 px-1">
            <img
            src={Logo}
            width="55"
            height="35"
            className="d-inline-block"
            />
              
          </Navbar.Brand>
        </LinkContainer>
        <Badge bg ="secondary-outline" style={{ marginRight: 0, paddingLeft: 0 }}>
          { isLoading && <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          }
          <DropdownSelector
            labelName=""
            options={routeOptions}
            defaultValue={routeAppContext.id}
            onChange={onChangeRoute}
          />
        </Badge>
        <Navbar.Toggle className="mx-0 px-1"/>
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname} pullRight onSelect={handleSelect}>
          { ((user !== null) ? user.is_staff : false) ? (
          <>
            {isAuthenticated ? (
              <>
                <LinkContainer to="/userdetail">
                    <Nav.Link>{t('USERDETAIL')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/settings">
                  <Nav.Link>{t('SETTING')}</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signupbasic">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/loginbasic">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          ) : (
          <>
            {isAuthenticated ? (
              <>
                <LinkContainer to="/userdetail">
                    <Nav.Link>{t('USERDETAIL')}</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signupbasic">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/loginbasic">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          )}
            <LangDropdown/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      { isAuthenticated ? <SubNavigation currentLocation={currentLocation} /> : "" }
      <Navbar fixed="bottom" expand="md" className="navbar-dark bg-light px-2">
        <div className="col-sm-4">
        </div>
        <div className="col-sm-4">
          <div class="text-center">
            E-mail: <a href="mailto:raxi@sonnet.ai">raxi@sonnet.ai</a> <br/>
            <a href="http://sonnet.ai/"> sonnet.ai</a> ⓒ 2021-{new Date().getFullYear()}, {t('VERSION')}
          </div>
        </div>
        <div className="col-sm-4">
          <Button variant="light" onClick={onClickToast}>
              <BsFillAlarmFill />
              {alarmCount != 0 && <Badge bg="danger">{alarmCount}</Badge>}
            </Button>
        </div>
      </Navbar>
      <AppContext.Provider value={providerValue}>
        <ServiceWorkerWrapper />
        <Routes />
      </AppContext.Provider>
    </div>
   )
  );
}

export default App;

