import React, { useState } from "react";
import { Nav, Navbar, Stack, Form, Accordion, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import config from "../config";
import TermsOfService from "../components/TermsOfService";
import TermsOfPrivacy from "../components/TermsOfPrivacy";
import ToggleSwitch from "../components/ToggleSwitch";

export default function SignupBasic() {
  const { t } = useTranslation();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "OK",
    phone_number: "",
    authcode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(true);
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isSwitchOnService, setIsSwitchOnService] = useState(false);
  const [isSwitchOnPrivate, setIsSwitchOnPrivate] = useState(false);
  const [isSwitchOnAgeLimit, setIsSwitchOnAgeLimit] = useState(false);

  const onSwitchActionService = () => {
    setIsSwitchOnService(!isSwitchOnService);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPrivate = () => {
    setIsSwitchOnPrivate(!isSwitchOnPrivate);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionAgeLimit = () => {
    setIsSwitchOnAgeLimit(!isSwitchOnAgeLimit);
    //console.log(isSwitchOnAgeLimit);
  };

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      isConfirm &&
      isSwitchOnService &&
      isSwitchOnPrivate &&
      isSwitchOnAgeLimit
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isConfirm) {
      setIsLoading(true);
      axios.post(`${config.DJANGO_URL}/accounts/checksignup/`, {
                authcode: fields.authcode,
      }).then(function(response) {
        if (response.data.access === 'ok') {
          setIsConfirm(true);
          setMessage(t('AUTHOKMSG'));
        } else {
          setMessage(t('AUTHFAILMSG'));
        }
        setIsLoading(false);
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('AUTHFAILMSG'));
          }
        } else {
          onError(t('AUTHFAILMSG'));
        }
        
        userHasAuthenticated(false);
        setIsLoading(false);
      });
    } else {
      //1. email check
      axios.post(`${config.DJANGO_URL}/accounts/checklogin/`, {
        email: fields.email,
        password1: fields.password,
        password2: fields.confirmPassword
      }).then( function(response) {
        //console.log(response.data);
        onError("등록된 회원입니다. 이메일을 다시 확인해 주세요");
        //1. email check error
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if ( error.response.status === 445 ) {
            setIsLoading(true);
            //2. 회원등록
            axios.post(`${config.DJANGO_URL}/dj-rest-auth/registration/`, {
              email: fields.email,
              password1: fields.password,
              password2: fields.confirmPassword
            }).then( function(response) {
              //console.log(response.data);
              setMessage(response.data.detail);
              setNewUser("test");
              setIsLoading(false);
            }).catch(function(error) {
              //console.log(error.response.data);
              if (error && error.response && error.response.status) {
                if (error.response.status === 400) {
                  let emailcheck = "";
                  let passwordcheck = "";
                  emailcheck = "" + error.response.data.email;
                  passwordcheck = "" + error.response.data.password1;
                  onError(emailcheck + ',' + passwordcheck);
                } else if ( error.response.status === 444 ) {
                  onError(error.response.data.message);
                } else {
                  onError(t('PROCESS_FAIL'));
                }
              } else {
                onError(t('PROCESS_FAIL'));
              }

              setIsLoading(false);
            });

          } else {
            onError(t('PROCESS_FAIL'));
          }
        } else {
          onError(t('PROCESS_FAIL'));
        }
        
      });
    }
  }

  async function handleReSubmit(event) {
    event.preventDefault();

    //1. email check
    axios.post(`${config.DJANGO_URL}/accounts/checklogin/`, {
      email: fields.email
    }).then( function(response) {
      //console.log(response.data);
      onError("미인증 회원이 아닙니다. 이메일을 다시 확인해 주세요");
    //1. email check error
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 445 ) {
          onError(error.response.data.message);
        }
        else if ( error.response.status === 446 ) {
          //2. 미인증 회원인 경우에 인증메일 다시발송로직 추가
          setIsLoading(true);
          axios.post(`${config.DJANGO_URL}/accounts/againconfirm/`, {
            email: fields.email
          }).then( function(response) {
            //3. 회원등록
            axios.post(`${config.DJANGO_URL}/dj-rest-auth/registration/`, {
              email: fields.email,
              password1: fields.password,
              password2: fields.confirmPassword
            }).then( function(response) {
              //console.log(response.data);
              setMessage(response.data.detail);
              setNewUser("test");
              setIsLoading(false);
            }).catch(function(error) {
              //console.log(error.response.data);
              if (error && error.response && error.response.status) {
                if (error.response.status === 400) {
                  let emailcheck = "";
                  let passwordcheck = "";
                  emailcheck = "" + error.response.data.email;
                  passwordcheck = "" + error.response.data.password1;
                  onError(emailcheck + ',' + passwordcheck);
                } else if ( error.response.status === 444 ) {
                  onError(error.response.data.message);
                } else {
                  onError(t('PROCESS_FAIL'));
                }
              } else {
                onError(t('PROCESS_FAIL'));
              }

              setIsLoading(false);
            });
          }).catch(function (error) {
            onError(t('PROCESS_FAIL'));
            setIsLoading(false);
          });
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
    });
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
    axios.post(`${config.DJANGO_URL}/accounts/login/`, {
                email: fields.email,
                password: fields.password
    }).then(function(response) {

        axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('access_user', JSON.stringify(response.data.user));
        setUser(response.data.user);
        userHasAuthenticated(true);
        history.push("/");
        
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
        
        setMessage(t('LOGIN_FAIL'));
        userHasAuthenticated(false);
        setIsLoading(false);
    });
    
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>{message}</Form.Label>
          <Form.Text muted>발송된 메일에서 인증해 주세요.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="dark"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          로그인
        </LoaderButton>
      </Form>
    );
  }
  
  function renderForm() {
    return (
    <Stack gap={3}>
      <div className="bg-light border">
        <Nav sticky="top">
              <LinkContainer to="/signupsocial">
                  <Nav.Link>SNS {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/signupbasic">
                  <Nav.Link>E-mail {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
        </Nav>
      </div>
      {/*
      <div className="bg-light border">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="authcode" size="lg">
            <Form.Label>{t('AUTHCODE')}</Form.Label>
            <Form.Control
              type="text"
              onChange={handleFieldChange}
              value={fields.authcode}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={isConfirm}
          >
            {t('AUTHVERIFY')}
          </LoaderButton>
        </Form>
      </div>
      */}
      <div className="bg-light border">
      {isConfirm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" size="lg">
            <Form.Label>{t('EMAIL')}</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>{t('PASSWORD')}</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" size="lg">
            <Form.Label>{t('CONFIRMPASSWORD')}</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          <h6>{t('PASSWORDINFO')}</h6>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            {t('SIGNUP')}
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="dark"
            onClick={handleReSubmit}
            disabled={!validateForm()}
            isLoading={isLoading}
          >
            인증메일 재발송
          </LoaderButton>  
        </Form>
      ) : (
			  <>
			  <h5>{message}</h5>
			  </>
			)}
      </div>
      <div className="bg-light border">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm>
              <ToggleSwitch label={t('TERMSOFAGE')} isChecked={isSwitchOnAgeLimit} onChange={onSwitchActionAgeLimit}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFSERVICE')} isChecked={isSwitchOnService} onChange={onSwitchActionService}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFPRIVACY')} isChecked={isSwitchOnPrivate} onChange={onSwitchActionPrivate}/>
            </Col>
          </Row>
        </Container>
        <Accordion alwaysOpen="False">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t('TERMSOFSERVICE')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfService />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t('TERMSOFPRIVACY')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfPrivacy />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
    );
   }
   
   return (
    <>
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </>
   );
}