import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function StationInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [station, setStation] = useState(null);
  const [stationname, setStationname] = useState("");
  const [site_lat, setSite_lat] = useState("");
  const [site_lng, setSite_lng] = useState("");
  const [cmsid, setCmsid] = useState("");
  const [route, setRoute] = useState(null);
  const [icon_src, setIcon_src] = useState("");
  const [sectionno, setSectionno] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get(`/robotaxi/station/${id}/`).then(function(response) {
          //console.log(response.data);
          const station = response.data;
          setStation(station);
          setStationname(station.stationname);
          setSite_lat(station.site_lat);
          setSite_lng(station.site_lng);
          setRoute(station.route);
          setCmsid(station.cmsid);
          setIcon_src(station.icon_src);
          setSectionno(station.sectionno);
        }).catch(function (error) {
          //console.log(error);
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      stationname.length > 0 &&
      site_lat.length > 0 &&
      site_lng.length > 0
    );
  }
  
  function saveStation(station) {
    axiosInstance.put(`/robotaxi/station/${id}/`, station).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
          setIsLoading(false);
        }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      saveStation({
        stationname: stationname,
        site_lat: site_lat,
        site_lng: site_lng,
        cmsid: cmsid,
        route: route,
        icon_src: icon_src,
        sectionno: sectionno
      });
    
      history.push("/station");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteStation() {
    return axiosInstance.delete(`/robotaxi/station/${id}/`, station).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          onError(error);
          setIsLoading(false);
        }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteStation();
      
      history.push("/station");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {station && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="stationname">
                <Form.Label>정류장명</Form.Label>
                <Form.Control
                  type="text"
                  value={stationname}
                  onChange={(e) => setStationname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="site_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={site_lat}
                  onChange={(e) => setSite_lat(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="site_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={site_lng}
                  onChange={(e) => setSite_lng(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="cmsid">
                <Form.Label>CMS Stations ID</Form.Label>
                <Form.Control
                  type="text"
                  value={cmsid}
                  onChange={(e) => setCmsid(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="icon_src">
                <Form.Label>Icon경로</Form.Label>
                <Form.Control
                  type="text"
                  value={icon_src}
                  onChange={(e) => setIcon_src(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sectionno">
                <Form.Label>경로 Section</Form.Label>
                <Form.Control
                  type="text"
                  value={sectionno}
                  onChange={(e) => setSectionno(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default StationInfo;